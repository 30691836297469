<template>
    <v-container fluid class="list-system">
        <v-row>
        <v-col cols="12">
            <div class="mb-5 text-right">
            <v-btn @click="removeFilter()" v-if="filter" color="secondary" text dark class="mr-2">
                <v-icon>mdi-window-close</v-icon>
                Remove filter
            </v-btn>
            <v-dialog v-model="filterDialog" max-width="400">
                <template v-slot:activator="{on}">
                    <v-btn color="secondary" dark v-on="on" class="mr-2">
                        <v-icon>mdi-filter</v-icon>
                        Filter
                    </v-btn>
                </template>
                <v-card>
                     <tableFilter :filterFields="filterData" :systemList="systemList" v-on:filteredData="tableFilter"></tableFilter>
                </v-card>
            </v-dialog>
            <v-btn @click="edit()" dark color="primary">
                <v-icon>mdi-plus</v-icon>
                New
            </v-btn>
            </div>
            <v-data-table
            v-if="this.baseAssetList"
            :loading="this.loading" loading-text="Loading... Please wait"
            :headers="headers"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items="this.baseAssetTable"
            :server-items-length="pagination.count"
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:item="row">
              <tr>
                <td class="text-left">{{row.item.id}}</td>
                <td class="text-left">{{row.item.name}}</td>
                <td class="text-left">{{row.item.asset_count}}</td>
                <td class="text-left">{{row.item.abbreviation }}</td>
                <td class="text-left" v-if="row.item">{{  getSystemName(row.item.system) }}</td>
                <td style="width:150px" class="text-xs-center">
                    <v-btn-toggle>
                        <v-btn small fab icon color="grey"  @click="edit(row.item.id)">
                        <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-tooltip bottom v-if="row.item.asset_set.length > 0" class="mr-4" >
                            <template v-slot:activator="{ on }">
                                <v-btn small fab><v-icon color="grey" dark v-on="on">mdi-delete</v-icon></v-btn>
                            </template>
                            <span>These assets have to be removed first: 
                                <ul class="text-left">
                                    <li v-for="(asset, index) in row.item.asset_set" v-bind:key="index">
                                        {{ getAssetName(asset) }} ( ID: {{ asset }} )
                                    </li>    
                                </ul> 
                            </span>
                            </v-tooltip>
                        <v-btn small fab icon color="grey" v-if="row.item.asset_set.length == 0" @click="remove(row.item.id)" >
                        <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center">
              <v-pagination total-visible="7" class="mt-2 mb-2" v-model="pagination.current" :length="pagination.pages"></v-pagination>
              <div class="subtitle">Total: {{pagination.count}}</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import tableFilter from '@/components/filter/filterBaseAsset.vue'
	export default {
        components: {
            tableFilter,
        },
		data: () => ({
			success: false,
            loading: true,
            headers: [
            { text: 'ID', align: 'left', sortable: true, value: 'id', width: 100},
            { text: 'Name', value: 'name' },
            { text: 'Asset Count', value: 'asset_count' },
            { text: 'Abbreviation', value: 'abbreviation' },
            { text: 'System', value: 'system' },
            { text: ''}
            ],
			baseAssetList: [],
            systemList: [],
            assetList: [],

            filter: false,
            filterDialog: false,
            filterData: {},
            filteredBaseAssets: [],
            pagination:{
                count:0,
                pages: 1,
                current:1,
            },
            perPage: 20,
        }),
        computed: {
            baseAssetTable: function() {
                if (Object.keys(this.filterData).length > 0 && this.filter == true) {
                    return this.filteredBaseAssets
                } else {
                    return this.baseAssetList;
                }
            }
        },
		methods: {
            // Main Function
            list() {
			    this.getBaseAsset(1).then((res) => {
                    this.baseAssetList = res.results;
                    this.pagination.next = res.next
                    this.pagination.count = res.count
                    this.pagination.pages = Math.ceil(res.count / this.perPage)
                });
            },
			async getBaseAsset(page) {
                try {
                    this.loading = true
                    const res = await this.$api.baseAsset.search({ limit:this.perPage, offset: this.perPage*(page - 1) });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			edit(id) {
                this.$router.push({ name: 'baseAssetForm', params: { id } })
            },
            remove(id) {
                this.$swal({
                    icon: 'warning',
                    title: 'Are you sure to delete this?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
			            this.$api.baseAsset.delete(id).then(res => this.list() );
                    }
                })
            },
            // Additional Infos
            tableFilter(value) {
                this.filter = true;
                if (Object.keys(value).length > 0) {
                    // this.loading = true;
                    this.filterData = value;
			        this.$api.baseAsset.search(value).then((res) => {
                        this.filteredBaseAssets = res.data;
                        if (this.filterData.name) {
                            this.filteredBaseAssets = this.filteredBaseAssets.filter(baseAsset => 
                                baseAsset.name.toLowerCase().includes(this.filterData.name.toLowerCase())
                            )
                        }
                    });
                }
                setTimeout(() => this.loading = false, 2000 );
                this.filterDialog = false;
            },
            removeFilter() {
                this.filterData = {};
                this.filteredAsset = [];
                this.filter = false;
            },
            getSystemList() {
			    this.getSystems().then(res => this.systemList = res);
            },
			async getSystems() {
                try {
                    const res = await this.$api.system.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getSystemName(id) {
                if ( this.systemList.length !== 0 && id ) {
                    return this.systemList.find(system => system.id === id).name;
                }
            },
            getAssetList() {
			    this.getAssets().then(res => this.assetList = res);
            },
			async getAssets() {
                try {
                    const res = await this.$api.asset.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getAssetName(id) {
                if ( this.assetList.length !== 0 && id ) {
                    return this.assetList.find(asset => asset.id === id).name;
                }
            },
		},
		mounted () {
            this.list();
            this.getSystemList();
            this.getAssetList(); 
		},
        watch: {
            pagination: {
                handler(val){
                    this.getBaseAsset(this.pagination.current).then((res) => {
                        this.baseAssetList = res.results;
                    });
                },
                deep: true
            },
        },
	}
</script>