<template>
    <v-container fluid class="list-system">
      <v-row>
        <v-col cols="12">
            <div class="py-2 px-4 text-left command-button-list">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon>mdi-arrow-left</v-icon>
                    Back
                </v-btn>
            </div>
            <v-card v-if="!loading" class="pa-3 mb-3 mx-auto text-right">
                <v-tabs >
                    <v-tabs-slider />
                    <v-tab>System Info</v-tab>
                    <v-tab v-if="this.input.id">Child: Master Component</v-tab>
                    <v-tab v-if="this.input.id">Child: Base Asset</v-tab>
                    <v-tab-item>
                        <v-form lazy-validation
                        ref="form"
                        v-model="valid">
                            <v-row>
                                <v-col cols="12" sm="6" md="3">
                                    <v-text-field
                                        label="Name" 
                                        v-model="input.name"
                                        :rules="nameRules"
                                        :counter="50"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-text-field
                                        label="Abbreviation" 
                                        v-model="input.abbreviation"
                                        :rules="abbreRules"
                                        :counter="5"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="text-right">
                                    <v-btn class="mr-4" color="primary" :disabled="!valid" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-tab-item>
                    <v-tab-item>
                        <mastCompTable class="p-2" v-if="this.input.id" :systemId='this.input.id'></mastCompTable>
                    </v-tab-item>
                    <v-tab-item>
                        <baseAssetTable class="p-2" v-if="this.input.id" :systemId='this.input.id'></baseAssetTable>
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import mastCompTable from '@/components/table/master-component-table.vue';
import baseAssetTable from '@/components/table/base-asset-table.vue';
	export default {
		  components: {
              mastCompTable,
              baseAssetTable
		  },
          props: ['id'],
		data: () => ({
            success: false,
            loading: false,
            saving: false,

            input:{
                id: '',
                name: '',
                abbreviation: '',
            },
            valid: true,
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 50) || 'Name must be less than 50 characters',
            ],
            abbreRules: [
                v => !!v || 'Abbreviation is required',
                v => (v && v.length <= 5) || 'Abbreviation must be less than 5 characters',
            ],
		}),
		methods: {
            // Main Function
            form() {
                if (this.id) {
                    this.getSystem().then((res) => {
                        this.input.id = res.id;
                        this.input.name = res.name;
                        this.input.abbreviation = res.abbreviation;
                    });
                }
            },
			async getSystem() {
                try {
                    this.loading = true
                    const res = await this.$api.system.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			save() {
                this.saveSystem().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'systemList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                });
			},
			async saveSystem() {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.system.save( this.input );
                    } else {
                        res = await this.$api.system.new( this.input );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			quitEdit() {
                this.$router.push({ name: 'systemList' })
            },
		},
		mounted () {
            this.form();
            this.success = true;
		},
	}
</script>