<template>
    <div class="check-page">
        <v-row align="center" v-for="(param,i) in indexedParameters" :key="i" class="flex-wrap mt-3 mb-3">
            <v-col cols="12" sm="3" class="pt-0 pb-0">
                {{ getParamName(param.parameter)}}
            </v-col>
            <v-spacer v-if="profileType =='checker'"></v-spacer>
            <v-col cols="5" sm="2" class="pt-0 pb-0" v-if="profileType =='verifier'">
                <div>Checker: <span v-if="param.checker_reading"><br/>{{param.checker_reading}}</span><br/>{{param.checker_condition}}</div>
            </v-col>
            <v-col cols="5" sm="2" class="pt-0 pb-0" v-if="param.condition_mode == 'AUTOMATIC'">
                <v-text-field
                    v-if="profileType =='checker'"
                    label="Checker Reading"
                    v-model="param.checker_reading" 
                    hide-details
                    @change="changeCheckConditionIfAuto(param.index, param.condition_mode, param.automatic_mode, param.lower_limit, param.upper_limit, param.id)"
                    :suffix="getUnitSymbol(getParamUnit(param.parameter))"
                ></v-text-field>
                <v-text-field
                    v-if="profileType =='verifier'"
                    label="Verifier Reading"
                    v-model="param.verifier_reading" 
                    hide-details
                    @change="changeVerifyConditionIfAuto(param.index, param.condition_mode, param.automatic_mode, param.lower_limit, param.upper_limit, param.id)"
                    :suffix="getUnitSymbol(getParamUnit(param.parameter))"
                ></v-text-field>
                <div v-if="profileType == 'super'">Readings: {{param.checker_reading}}{{getUnitSymbol(getParamUnit(param.parameter))}} - {{param.verifier_reading}}{{getUnitSymbol(getParamUnit(param.parameter))}}</div>
            </v-col>
            <v-col cols="12" sm="5" md="4" class="pt-0 pb-0">
                <v-btn-toggle 
                v-if="profileType =='checker'" 
                dense 
                v-model="param.checker_condition"
                class="condition-btn-group"
                    @change="checked_ts(param.id, param.checker_reading, param.checker_condition)">
                    <v-btn 
                    v-for="(conditionValue, index) in condition" 
                    v-bind:key="index" 
                    :class="{'disable-events': param.condition_mode !== 'MANUAL' || param.checker_condition == conditionValue.value}"
                    :value="conditionValue.value"
                    :color="conditionValue.color">
                        <!-- {{conditionValue.name}} -->
                        <v-icon>{{conditionValue.icon}}</v-icon>
                    </v-btn>
                </v-btn-toggle>
                <v-btn-toggle 
                v-if="profileType =='verifier'" 
                dense 
                v-model="param.verifier_condition"
                class="condition-btn-group"
                    @change="verified_ts(param.id, param.verifier_reading, param.verifier_condition)">
                    <v-btn 
                    v-for="(conditionValue, index) in condition" 
                    v-bind:key="index" 
                    :class="{'disable-events': param.condition_mode !== 'MANUAL' || param.verifier_condition == conditionValue.value}"
                    :value="conditionValue.value" 
                    :color="conditionValue.color">
                        <!-- {{conditionValue.name}} -->
                        <v-icon>{{conditionValue.icon}}</v-icon>
                    </v-btn>
                </v-btn-toggle>
                <div v-if="profileType == 'super'">Conditions: {{param.checker_condition}} - {{param.verifier_condition}}</div>
            </v-col>
            <v-col cols="2" sm="1" class="pt-0 pb-0 ml-auto">
                <v-btn
                class=""
                icon
                @click="parameterChecksbyAssetCheck[param.index].openNoteDialog = !parameterChecksbyAssetCheck[param.index].openNoteDialog"
                >
                <v-icon>mdi-tooltip-edit-outline</v-icon>
                </v-btn>

                <v-dialog
                v-model="parameterChecksbyAssetCheck[param.index].openNoteDialog"
                max-width="420"
                >
                <v-card>
                    <v-card-title class="headline">Note</v-card-title>
                    <v-card-text>
                        <v-text-field
                            label="Checker Note"
                            :readonly="profileType =='verifier' || profileType =='super' "
                            v-model="param.checker_note" 
                            hide-details
                            @change="checked_note(param.id, param.checker_note)"
                        ></v-text-field>
                        <v-text-field
                            v-if="profileType =='verifier' || profileType =='super' "
                            :readonly="profileType =='super' "
                            label="Verifier Note"
                            v-model="param.verifier_note" 
                            hide-details
                            @change="verified_note(param.id, param.verifier_note)"
                        ></v-text-field>
                    </v-card-text>
                </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-row v-if="indexedParameters.length > 0 && profileType =='verifier'" >
            <v-col cols="12" align="end">
                <v-btn @click="bulkVerify()" depressed color="primary">Verify <v-icon class="ml-2">mdi-check-all</v-icon></v-btn>
            </v-col>
        </v-row>
  </div>
    
</template>
<script>
import Vue from 'vue'
	export default {
        props: {
            assetCheckId: {
                type: Number
            },
            assetId: {
                type: Number
            },
            assetListFull: {
                type: Array
            },
            masterParamList: {
                type: Array
            },
            baseParamList: {
                type: Array
            },
            unitList: {
                type: Array
            },
            checkverifyStatus: String,
            profileType: String,
        },
		data() {
            return {
                id: [],
                loading: true,
                parameterChecksbyAssetCheck: [],
                users:[],
                parameterList: [],
                condition: [
                    { 
                        name: 'Unknown',
                        value: 'UNKNOWN',
                        icon: 'mdi-help',
                        color:'grey lighten-1'
                    },
                    { 
                        name: 'Good',
                        value: 'GOOD',
                        icon: 'mdi-check',
                        color:'green'
                    },
                    { 
                        name: 'Bad',
                        value: 'BAD',
                        icon: 'mdi-close',
                        color:'red'
                    },
                    { 
                        name: 'Other',
                        value: 'OTHER',
                        icon: 'mdi-alert',
                        color:'orange'
                    },
                ],
                expandable: {},
                assetCheck: 0,
            }
        },
        computed: {
            indexedParameters () {
                return this.parameterChecksbyAssetCheck;
                // return this.parameterChecksbyAssetCheck.map((item, index) => ({
                //     index: index,
                //     ...item
                // }))
            },
        },
        methods: {
            list() {
                if (this.assetCheckId) {
                    this.getParamChecksbyAssetCheck().then((res) => {
                        this.parameterChecksbyAssetCheck = res;
                        this.parameterChecksbyAssetCheck.forEach((item, i) => { 
                            Vue.set(item,'index', i);
                            Vue.set(item,'openNoteDialog', false);
                        })
                    });
                }
            },
			async getParamChecksbyAssetCheck() {
                try {
                    this.loading = true
                    const res = await this.$api.paramCheck.search({ asset_check: this.assetCheckId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            getAdditionalData() {
			    this.getParamsbyAsset().then(res => this.parameterList = res);
            },
			async getParamsbyAsset() {
                try {
                    this.loading = true
                    const res = await this.$api.parameter.search({ asset: this.assetId});
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            getParamName(param_id) {
                if ( this.masterParamList.length !== 0 && this.baseParamList.length && this.parameterList.length !== 0 && param_id ) {
                    return (this.masterParamList.find(baseParam => baseParam.id === (this.baseParamList.find(baseParam => baseParam.id === (this.parameterList.find(param => param.id === param_id).base_parameter)).master_parameter)).name)
                }
            },
            getParamUnit(param_id) {
                if ( this.masterParamList.length !== 0 && this.baseParamList.length && this.parameterList.length !== 0 && param_id ) {
                    return this.baseParamList.find(baseParam => baseParam.id === (this.parameterList.find(param => param.id === param_id).base_parameter)).unit
                }
            },
            getUnitSymbol(unit_id) {
                if ( this.unitList.length !== 0 && unit_id ) {
                    return this.unitList.find(unit => unit.id === unit_id).symbol;
                }
            },
            changeCheckConditionIfAuto(index, condition_mode, automatic_mode, lower_limit, upper_limit, id ) {
                if (condition_mode == 'AUTOMATIC') {
                    if (automatic_mode == 'LOWER') {
                        if (this.indexedParameters[index].checker_reading && this.indexedParameters[index].checker_reading > lower_limit) {
                            Vue.set(this.parameterChecksbyAssetCheck[index],'checker_condition', 'GOOD');
                        } else {
                            Vue.set(this.parameterChecksbyAssetCheck[index],'checker_condition', 'BAD');
                        }
                    }
                    if (automatic_mode == 'UPPER') {
                        if (this.indexedParameters[index].checker_reading && this.indexedParameters[index].checker_reading < upper_limit) {
                            Vue.set(this.parameterChecksbyAssetCheck[index],'checker_condition', 'GOOD');
                        } else {
                            Vue.set(this.parameterChecksbyAssetCheck[index],'checker_condition', 'BAD');
                        }
                    }
                    if (automatic_mode == 'RANGE') {
                        if (this.indexedParameters[index].checker_reading && parseFloat(this.indexedParameters[index].checker_reading) < parseFloat(upper_limit) && parseFloat(this.indexedParameters[index].checker_reading) > parseFloat(lower_limit)) {
                            Vue.set(this.parameterChecksbyAssetCheck[index],'checker_condition', 'GOOD');
                        } else {
                            Vue.set(this.parameterChecksbyAssetCheck[index],'checker_condition', 'BAD');
                        }
                    }
                } else {
                    // do nothing
                }
                this.checked_ts(id, this.indexedParameters[index].checker_reading, this.indexedParameters[index].checker_condition);
            },
            changeVerifyConditionIfAuto(index, condition_mode, automatic_mode, lower_limit, upper_limit, id ) {
                if (condition_mode == 'AUTOMATIC') {
                    if (automatic_mode == 'LOWER') {
                        if (this.indexedParameters[index].verifier_reading && this.indexedParameters[index].verifier_reading > lower_limit) {
                            Vue.set(this.indexedParameters[index],'verifier_condition', 'GOOD');
                        } else {
                            Vue.set(this.indexedParameters[index],'verifier_condition', 'BAD');
                        }
                    }
                    if (automatic_mode == 'UPPER') {
                        if (this.indexedParameters[index].verifier_reading && this.indexedParameters[index].verifier_reading < upper_limit) {
                            Vue.set(this.indexedParameters[index],'verifier_condition', 'GOOD');
                        } else {
                            Vue.set(this.indexedParameters[index],'verifier_condition', 'BAD');
                        }
                    }
                    if (automatic_mode == 'RANGE') {
                        if (this.indexedParameters[index].verifier_reading && parseFloat(this.indexedParameters[index].verifier_reading) < parseFloat(upper_limit) && parseFloat(this.indexedParameters[index].verifier_reading) > parseFloat(lower_limit)) {
                            Vue.set(this.indexedParameters[index],'verifier_condition', 'GOOD');
                        } else {
                            Vue.set(this.indexedParameters[index],'verifier_condition', 'BAD');
                        }
                    }
                } else {
                    // do nothing
                }
                this.verified_ts(id, this.indexedParameters[index].verifier_reading, this.indexedParameters[index].verifier_condition);
            },
            checked_ts(id, checker_reading, checker_condition) {
                var d = new Date();
                this.$api.paramCheck.save({id:id, checker_ts: d, checker_reading: checker_reading, checker_condition: checker_condition}).then((res) => {
                    if (res.status == 200 ) {
                            this.$emit('reloadAssetCheck');
						}
					})
            },
            verified_ts(id, verifier_reading, verifier_condition) {
                var d = new Date();
				this.$api.paramCheck.save({ id: id, verifier_ts: d, verifier_reading:verifier_reading, verifier_condition: verifier_condition }).then((res) => {
                    if (res.status == 200 ) {
                            this.$emit('reloadAssetCheck');
						}
					})
            },
            bulkVerify() {
                var d = new Date();
                this.$swal({
                    icon: 'warning',
                    title: 'Do you want to bulk verify?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
                        this.verifyLoop(d).then((res) => {
                            if (res.status == 'Done') {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Bulk Verify Complete',
                                    text: res.updated +' updated!',
                                    confirmButtonText: 'Ok',
                                }).then((result) => {
                                    this.list();
                                    this.$emit('reloadAssetCheck')
                                })
                            }
                        });
                    }
                })
            },
			async verifyLoop(d) {
                try {
                    let res;
                    let numberUpdated = 0;
                    this.saving = true
                    for (var i = 0; i < this.indexedParameters.length; i++) {
                        if (this.indexedParameters[i].verifier_condition == '' || this.indexedParameters[i].verifier_condition == 'UNKNOWN') {
                            this.indexedParameters[i].verifier_reading = this.indexedParameters[i].checker_reading;
                            this.indexedParameters[i].verifier_condition = this.indexedParameters[i].checker_condition;
                            await this.$api.paramCheck.save( {id: this.indexedParameters[i].id, verifier_reading: this.indexedParameters[i].verifier_reading, verifier_condition: this.indexedParameters[i].verifier_condition, verifier_ts: d });
                            numberUpdated++;
                        }  
                    }
                    return { status:'Done', updated: numberUpdated };
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            checked_note (id, checker_note) {
				this.$api.paramCheck.save({ id: id, checker_note: checker_note }).then((res) => {
                    if (res.status == 200 ) {
                            this.$emit('reloadAssetCheck');
						}
					})
            },
            verified_note (id, verifier_note) {
				this.$api.paramCheck.save({ id: id, verifier_note: verifier_note }).then((res) => {
                    if (res.status == 200 ) {
                            this.$emit('reloadAssetCheck');
						}
					})
            },
            newParameterCheck() {
                this.parameterChecksbyAssetCheck.push({id: '', checker_reading: null, verifier_reading: null, checker_condition: '', verifier_condition: '', checker_note: '', verifier_note: '', checker_ts:'', verifier_ts: '', asset_check: this.assetCheck});
            },
            save(id, checker_reading, verifier_reading, checker_condition, verifier_condition, checker_note, verifier_note, checker_ts, verifier_ts, asset_check) {
                this.saveParamCheck(id, checker_reading, verifier_reading, checker_condition, verifier_condition, checker_note, verifier_note, checker_ts, verifier_ts, asset_check).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                        })
                    }
                });
			},
			async saveParamCheck(id, checker_reading, verifier_reading, checker_condition, verifier_condition, checker_note, verifier_note, checker_ts, verifier_ts, asset_check) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.paramCheck.save( {id: id, checker_reading: checker_reading, verifier_reading: verifier_reading, checker_condition: checker_condition, verifier_condition: verifier_condition, checker_note: checker_note, verifier_note: verifier_note, checker_ts: checker_ts, verifier_ts: verifier_ts, asset_check: asset_check });
                    } else {
                        res = await this.$api.paramCheck.new( {checker_reading: checker_reading, verifier_reading: verifier_reading, checker_condition: checker_condition, verifier_condition: verifier_condition, checker_note: checker_note, verifier_note: verifier_note, checker_ts: checker_ts, verifier_ts: verifier_ts, asset_check: asset_check  });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.paramCheck.delete(id).then((res) => {
                                this.list();
                            });
                        }
                    })
                } else {
                    this.$delete(this.parameterChecksbyAssetCheck, index)
                }
            },
        },
        mounted(){
            this.list();
            this.getAdditionalData();
        }
    }
</script>