import axios from 'axios'
import store from './store'
var wormsApi = axios.create({
	baseURL: process.env.VUE_APP_API,
	timeout: 180000,
	//headers: {'Authorization': localStorage.getItem("storeToken")}
});
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'
wormsApi.interceptors.request.use(
	function (config) {
		// if (process.env.VUE_APP_MODE == 'DEVELOPMENT') {
		// config.headers.request_startTime = new Date()
		// }
		const token = store.getters.jwt;
		if (token) config.headers.Authorization = store.getters.jwt;
    //  Generate cancel token source
    let source = axios.CancelToken.source();

    // Set cancel token on this request
    config.cancelToken = source.token;

    // Add to vuex to make cancellation available from anywhere
    store.commit('ADD_CANCEL_TOKEN', source);
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);
wormsApi.interceptors.response.use(function (response) {
	
    return response;
}, function (error) {
    if (!error.response) {
		if (axios.isCancel(err)){
			console.log('cancelled');
		} else {
			console.log('NETWORK ERROR')
		}
    } else {
        if (error.response.status == '401') {
			store.dispatch('tokenError');
			store.dispatch('inspectToken');
        }
        if (error.response.status == '403') {
			store.dispatch('tokenError');
			store.dispatch('refreshToken');
        }
        if (error.response.status == '400' && error.response.statusText == 'Bad Request') {
			store.dispatch('apiError', (JSON.stringify(error.response.data).replace(/[\])}[{("]/g, '').replace(/[,]/g, '\n')));
        }
        return Promise.reject(error)
    }
});

var alphabet = [null, 'one', 'two', 'three', 'four', 'five']
const system = {
	list: () => wormsApi.get('api/v1/system/'),
	search: (params) => wormsApi.get('api/v1/system/', { params }),
	get: (id) => wormsApi.get('api/v1/system/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/system/' + item.id + '/', item),
	new: (item) => wormsApi.post('api/v1/system/', item),
	delete: (id) => wormsApi.delete('api/v1/system/' + id + '/'),
}
const baseAsset = {
	list: () => wormsApi.get('api/v1/base-asset/'),
	search: (params) => wormsApi.get('api/v1/base-asset/', { params }),
	get: (id) => wormsApi.get('api/v1/base-asset/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/base-asset/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/base-asset/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/base-asset/', item),
	delete: (id) => wormsApi.delete('api/v1/base-asset/' + id + '/'),
}
const mastComp = {
	list: () => wormsApi.get('api/v1/master-component/'),
	search: params => wormsApi.get('api/v1/master-component/', { params }),
	get: (id) => wormsApi.get('api/v1/master-component/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/master-component/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/master-component/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/master-component/', item),
	delete: (id) => wormsApi.delete('api/v1/master-component/' + id + '/'),
}
const mastParam = {
	list: () => wormsApi.get('api/v1/master-parameter/'),
	search: params => wormsApi.get('api/v1/master-parameter/', { params }),
	get: (id) => wormsApi.get('api/v1/master-parameter/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/master-parameter/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/master-parameter/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/master-parameter/', item),
	delete: (id) => wormsApi.delete('api/v1/master-parameter/' + id + '/'),
}
const asset = {
	list: () => wormsApi.get('api/v1/asset/'),
	search: (params) => wormsApi.get('api/v1/asset/', { params }),
	get: (id) => wormsApi.get('api/v1/asset/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/asset/' + item.id + '/', item),
	new: (item) => wormsApi.post('api/v1/asset/', item),
	delete: (id) => wormsApi.delete('api/v1/asset/' + id + '/'),
}
const baseUnit = {
	list: () => wormsApi.get('api/v1/base-unit/'),
	search: params => wormsApi.get('api/v1/base-unit/', { params }),
	get: (id) => wormsApi.get('api/v1/base-unit/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/base-unit/' + item.id + '/', item),
	new: (item) => wormsApi.post('api/v1/base-unit/', item),
	delete: (id) => wormsApi.delete('api/v1/base-unit/' + id + '/'),
}
const baseComp = {
	list: () => wormsApi.get('api/v1/base-component/'),
	search: params => wormsApi.get('api/v1/base-component/', { params }),
	get: (id) => wormsApi.get('api/v1/base-component/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/base-component/' + item.id + '/', item),
	new: (item) => wormsApi.post('api/v1/base-component/', item),
	delete: (id) => wormsApi.delete('api/v1/base-component/' + id + '/'),
}
const baseParam = {
	list: () => wormsApi.get('api/v1/base-parameter/'),
	search: params => wormsApi.get('api/v1/base-parameter/', { params }),
	get: (id) => wormsApi.get('api/v1/base-parameter/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/base-parameter/' + item.id + '/', item),
	new: (item) => wormsApi.post('api/v1/base-parameter/', item),
	delete: (id) => wormsApi.delete('api/v1/base-parameter/' + id + '/'),
}
const unit = {
	list: () => wormsApi.get('api/v1/unit/'),
	search: params => wormsApi.get('api/v1/unit/', { params }),
	get: (id) => wormsApi.get('api/v1/unit/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/unit/' + item.id + '/', item),
	new: (item) => wormsApi.post('api/v1/unit/', item),
	delete: (id) => wormsApi.delete('api/v1/unit/' + id + '/'),
}
const checkplan = {
	list: () => wormsApi.get('api/v1/checkplan/'),
	search: params => wormsApi.get('api/v1/checkplan/', { params }),
	get: (id) => wormsApi.get('api/v1/checkplan/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/checkplan/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/checkplan/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/checkplan/', item),
	delete: (id) => wormsApi.delete('api/v1/checkplan/' + id + '/'),
}
const assetPlan = {
	list: () => wormsApi.get('api/v1/asset-plan/'),
	search: params => wormsApi.get('api/v1/asset-plan/', { params }),
	get: (id) => wormsApi.get('api/v1/asset-plan/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/asset-plan/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/asset-plan/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/asset-plan/', item),
	delete: (id) => wormsApi.delete('api/v1/asset-plan/' + id + '/'),
}
const paramPlan = {
	list: () => wormsApi.get('api/v1/parameter-plan/'),
	search: params => wormsApi.get('api/v1/parameter-plan/', { params }),
	get: (id) => wormsApi.get('api/v1/parameter-plan/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/parameter-plan/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/parameter-plan/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/parameter-plan/', item),
	delete: (id) => wormsApi.delete('api/v1/parameter-plan/' + id + '/'),
}
const compPlan = {
	list: () => wormsApi.get('api/v1/component-plan/'),
	search: params => wormsApi.get('api/v1/component-plan/', { params }),
	get: (id) => wormsApi.get('api/v1/component-plan/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/component-plan/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/component-plan/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/component-plan/', item),
	delete: (id) => wormsApi.delete('api/v1/component-plan/' + id + '/'),
}
const checklist = {
	list: () => wormsApi.get('api/v1/checklist/'),
	search: params => wormsApi.get('api/v1/checklist/', { params }),
	get: (id) => wormsApi.get('api/v1/checklist/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/checklist/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/checklist/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/checklist/', item),
	delete: (id) => wormsApi.delete('api/v1/checklist/' + id + '/'),
}
const assetCheck = {
	list: () => wormsApi.get('api/v1/asset-check/'),
	search: params => wormsApi.get('api/v1/asset-check/', { params }),
	get: (id) => wormsApi.get('api/v1/asset-check/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/asset-check/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/asset-check/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/asset-check/', item),
	delete: (id) => wormsApi.delete('api/v1/asset-check/' + id + '/'),
}
const paramCheck = {
	list: () => wormsApi.get('api/v1/parameter-check/'),
	search: params => wormsApi.get('api/v1/parameter-check/', { params }),
	get: (id) => wormsApi.get('api/v1/parameter-check/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/parameter-check/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/parameter-check/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/parameter-check/', item),
	delete: (id) => wormsApi.delete('api/v1/parameter-check/' + id + '/'),
}
const compCheck = {
	list: () => wormsApi.get('api/v1/component-check/'),
	search: params => wormsApi.get('api/v1/component-check/', { params }),
	get: (id) => wormsApi.get('api/v1/component-check/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/component-check/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/component-check/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/component-check/', item),
	delete: (id) => wormsApi.delete('api/v1/component-check/' + id + '/'),
}
const recurrence = {
	list: () => wormsApi.get('api/v1/recurrence/'),
	search: params => wormsApi.get('api/v1/recurrence/', { params }),
	get: (id) => wormsApi.get('api/v1/recurrence/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/recurrence/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/recurrence/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/recurrence/', item),
	delete: (id) => wormsApi.delete('api/v1/recurrence/' + id + '/'),
}
const recurrenceTime = {
	list: () => wormsApi.get('api/v1/recurrence-time/'),
	search: params => wormsApi.get('api/v1/recurrence-time/', { params }),
	get: (id) => wormsApi.get('api/v1/recurrence-time/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/recurrence-time/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/recurrence-time/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/recurrence-time/', item),
	delete: (id) => wormsApi.delete('api/v1/recurrence-time/' + id + '/'),
}
const team = {
	list: () => wormsApi.get('api/v1/team/'),
	search: params => wormsApi.get('api/v1/team/', { params }),
	get: (id) => wormsApi.get('api/v1/team/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/team/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/team/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/team/', item),
	delete: (id) => wormsApi.delete('api/v1/team/' + id + '/'),
}
const user = {
	list: () => wormsApi.get('api/v1/user/'),
	listNameRef: () => wormsApi.get('api/v1/user/name-id/'),
	search: params => wormsApi.get('api/v1/user/', { params }),
	get: (id) => wormsApi.get('api/v1/user/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/user/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/user/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/user/', item),
	delete: (id) => wormsApi.delete('api/v1/user/' + id + '/'),
}
const member = {
	list: () => wormsApi.get('api/v1/member/'),
	search: params => wormsApi.get('api/v1/member/', { params }),
	get: (id) => wormsApi.get('api/v1/member/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/member/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/member/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/member/', item),
	delete: (id) => wormsApi.delete('api/v1/member/' + id + '/'),
}
const level = {
	list: (level) => wormsApi.get('api/v1/level-'+alphabet[level]+'/'),
	search: (level,params) => wormsApi.get('api/v1/level-'+alphabet[level]+'/', { params }),
	get: (level, id) => wormsApi.get('api/v1/level-'+alphabet[level]+'/' + id + '/'),
	save: (level, item) => wormsApi.patch('api/v1/level-'+alphabet[level]+'/' + item.id + '/', item),
	saveForm: (level, id, item) => wormsApi.patch('api/v1/level-'+alphabet[level]+'/' + id + '/', item),
	new: (level, item) => wormsApi.post('api/v1/level-'+alphabet[level]+'/', item),
	delete: (level, id) => wormsApi.delete('api/v1/level-'+alphabet[level]+'/' + id + '/'),
}
const component = {
	list: () => wormsApi.get('api/v1/component/'),
	search: (params) => wormsApi.get('api/v1/component/', { params }),
	get: (id) => wormsApi.get('api/v1/component/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/component/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/component/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/component/', item),
	delete: (id) => wormsApi.delete('api/v1/component/' + id + '/'),
}
const parameter = {
	list: () => wormsApi.get('api/v1/parameter/'),
	search: params => wormsApi.get('api/v1/parameter/', { params }),
	get: (id) => wormsApi.get('api/v1/parameter/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/parameter/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/parameter/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/parameter/', item),
	delete: (id) => wormsApi.delete('api/v1/parameter/' + id + '/'),
}
const preference = {
	global: () => wormsApi.get('api/v1/global-preference/'),
	user: () => wormsApi.get('api/v1/user-preference/'),
}
const accountProfile = {
	get: () => wormsApi.get('api/account/profile/'),
	register: (item) => wormsApi.post('api/account/register/', item),
	verifyRegister: (item) => wormsApi.post('api/account/verify-registration/', item),
	changeEmail: (email) => wormsApi.post('api/account/register-email/', email),
	verifyEmail: (item) => wormsApi.post('api/account/verify-email/', item),
	changePassword: (item) => wormsApi.post('api/account/change-password/', item),
	sendPassword: (login) => wormsApi.post('api/account/send-reset-password-link/', login),
	resetPassword: (item) => wormsApi.post('api/account/reset-password/', item),
}
const statistics = {
	today: () => wormsApi.get('api/v1/checklist/stat/today/'),
}
export {
	wormsApi,
}
export default {
	mastComp,
	mastParam, 
	baseAsset,
	baseUnit,
	baseComp,
	baseParam, 
	asset,
	system,
	unit,
	checkplan,
	assetPlan,
	paramPlan,
	compPlan,
	checklist,
	recurrence,
	recurrenceTime,
	preference,
	team,
	user,
	member, 
	level,
	component,
	parameter,
	assetCheck,
	paramCheck,
	compCheck,
	accountProfile,
	statistics,
}