<template>
    <div class="pa-5">
        <v-form @submit.prevent="filter()">
            <v-text-field
                label="Name" 
                v-model="filterFields.name"
            ></v-text-field>
            <v-select
            :items="systemListSelect" 
            item-value="id"
            name="item"
            item-text="name"
            label="System" 
            v-model="filterFields.system"
            ></v-select>
            <v-btn color="primary" type="submit">Filter</v-btn>
        </v-form>
    </div>
</template>
<script>
	export default {
		  components: {
          },
		data: () => ({
            systemListSelect:[],
        }),
        props: {
            tableType: {
                type: String,
            },
            filterFields: {
                type: Object,
            },
            systemList: {
                type: Array,
                required: false
            }
        },
        computed: {
        },
        methods: {
            filter(event){
                this.$emit('filteredData', this.filterFields)
            }
        },
        mounted() {
            this.systemListSelect = this.systemList
            this.systemListSelect.unshift({
                id:'',
                name: 'All Systems',
            });
        }
    }
</script>