<template>
    <v-form v-if="!loading"
            lazy-validation
            ref="form"
            v-model="valid">
        <v-container fluid>
            <v-row>
                <v-col cols="12" sm="6" md="3">
                    <v-text-field
                        label="Name" 
                        v-model="input.name"
                        :rules="nameRules"
                        :counter="50"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <v-text-field
                        label="Abbreviation" 
                        v-model="input.abbreviation"
                        :rules="abbreRules"
                        :counter="5"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <v-select
                    :items="systemList" 
                    item-value="id"
                    name="item"
                    item-text="name"
                    label="System" 
                    v-model="input.system"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" md="3">
                    <label>Icongraphic</label><br/>
                    <v-img v-if="input.icongraphic" :src="input.icongraphic" width="180" height="180"></v-img>
                    <v-img v-if="!input.icongraphic" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                </v-col>
                <v-col cols="6" md="3">
                    <v-file-input
                        ref="file"
                        :rules="iconRules"
                        accept="image/png, image/jpeg"
                        placeholder="Upload"
                        prepend-icon="mdi-camera"
                        label="Add or Replace Icongraphic"
                        v-model="newIcon"
                    ></v-file-input>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="text-right">
                    <v-btn class="mr-4" color="primary" :disabled="!valid" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>
<script>
	export default {
        props: {
            mastCompList: {
               type: Array
            },
            mastCompId: {
                type: Number
            },
            systemId: {
                type: Number
            }
        },
		data() {
            return {
                loading: false,
                input:{
                    id: '',
                    name: '',
                    abbreviation: '',
                    system: '',
                    icongraphic: null,
                },
                systemList: [],
                valid: true,
                nameRules: [
                    v => !!v || 'Name is required',
                    v => (v && v.length <= 50) || 'Name must be less than 50 characters',
                ],
                abbreRules: [
                    v => !!v || 'Abbreviation is required',
                    v => (v && v.length <= 5) || 'Abbreviation must be less than 5 characters',
                ],
                iconRules: [
                    v => !v || v.size < 2000000 || 'Avatar size should be less than 2 MB!',
                ],
                newIcon: null
            }
        },
        computed: {
            // indexedUnit () {
            //     return this.mastCompList.map((item, index) => ({
            //         index: index,
            //         ...item
            //     }))
            // },
        },
        methods: {
            form() {
                if (this.mastCompId) {
                    this.getMastComp().then((res) => {
                        this.input.id = res.id;
                        this.input.name = res.name;
                        this.input.abbreviation = res.abbreviation;
                        this.input.system = res.system;
                        this.input.icongraphic = res.icongraphic;
                    });
                }
            },
			async getMastComp() {
                try {
                    this.loading = true
                    const res = await this.$api.mastComp.get(this.mastCompId);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			save() {
                this.saveMastComp().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Stay',
                        }).then((result) => {
                            if (result.value) {
                                this.$emit('return-form')
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                })
            },
			async saveMastComp() {
                try {
                    let res;
                    this.saving = true
                    let formData = new FormData();

                    formData.append("id", this.input.id);
                    formData.append("name", this.input.name);
                    formData.append("abbreviation", this.input.abbreviation);
                    formData.append("system", this.input.system);
                    if (this.newIcon) {
                        formData.append("icongraphic", this.newIcon);
                    }
                    if (this.input.id) {
                        res = await this.$api.mastComp.saveForm( this.input.id, formData );
                    } else {
                        res = await this.$api.mastComp.new( formData );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            getSystemList() {
			    this.getSystems().then(res => this.systemList = res);
            },
			async getSystems() {
                try {
                    const res = await this.$api.system.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            
        },
        mounted(){
            this.form();
            this.getSystemList();
        }
    }
</script>