<template>
    <div>
        <v-data-table 
            :loading="this.loading" loading-text="Loading... Please wait"
            :headers="headersUnits"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items="indexedUnit"
            :items-per-page="20"
            :footer-props="{
                itemsPerPageOptions: [20,40,60],
            }" 
            class="full-width">
            <template v-slot:item="row">
                <tr>
                <td>
                <v-text-field
                    v-model="row.item.name" 
                    required
                ></v-text-field>
                </td>
                <td>
                <v-text-field
                    v-model="row.item.symbol" 
                    required
                ></v-text-field>
                </td>
                <td class="text-left">
                    <v-text-field
                        type="number"
                        v-model="row.item.converter" 
                        min="0"
                    ></v-text-field>
                </td>
                <td class="text-left">
                    <div v-if="row.item.converter !== 0">
                    1{{row.item.symbol}} = {{row.item.converter / 1  | round(5) }}{{parentSymbol}} <br/>
                    1{{parentSymbol}} = {{ 1 / row.item.converter  | round(5)}}{{row.item.symbol}}
                    </div>
                </td>
                <td>
                    <v-btn-toggle>
                        <v-btn small fab @click="save(row.item.id, row.item.name, row.item.symbol, row.item.converter)"><v-icon>mdi-content-save</v-icon></v-btn>
                        <v-btn small fab @click="remove(row.item.index, row.item.id)"><v-icon>mdi-delete</v-icon></v-btn>
                    </v-btn-toggle>
                </td>
                </tr>
            </template>
        </v-data-table>
        <div><v-btn class="mr-4" @click="newUnit()">Add Unit</v-btn></div>
    </div>
    
</template>
<script>
	export default {
        props: {
            baseUnitList: {
               type: Array
            },
            unitId: {
                type: String
            },
            baseUnitId: {
                type: Number
            },
            parentSymbol: {
                type: String
            }
        },
		data() {
            return {
                loading: false,
                headersUnits: [
                { text: 'Unit', align: 'left', sortable: true, value: 'unit', },
                { text: 'Symbol', value: 'symbol' },
                { text: 'Converter', value: 'converter' },
                { text: '' },
                { text: ''}
                ],
                unitList: [],
            }
        },
        computed: {
            indexedUnit () {
                return this.unitList.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
			list() {
			    this.getUnitsbyBaseUnit().then(res => this.unitList = res);
            },
			async getUnitsbyBaseUnit() {
                try {
                    this.loading = true
                    const res = await this.$api.unit.search({ base_unit: this.baseUnitId });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            newUnit() {
                this.unitList.push({id: '', name: '', symbol: '', converter: 0});
            },
            save(id, name, symbol, converter) {
                this.saveUnit(id, name, symbol, converter, this.baseUnitId).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            reverseButtons: true,
                            confirmButtonText: 'OK',
                        }).then((result) => {
                        })
                        this.list();
                    }
                });
			},
			async saveUnit(id, name, symbol, converter, base_unit) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.unit.save( {id: id, name: name, symbol: symbol, converter: converter, base_unit: base_unit });
                    } else {
                        res = await this.$api.unit.new({name: name, symbol: symbol, converter: converter,  base_unit: base_unit });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
			               this.$api.unit.delete(id).then(res => this.list() );
                        }
                    })
                } else {
                    this.$delete(this.unitList, index)
                }
            },
        },
        mounted(){
            this.list();
        }
    }
</script>