<template>
    <v-form>
        <v-container fluid>
            <v-row>
                <v-col cols="12" sm="6" md="3">
                    <v-text-field
                        label="Username" 
                        v-model="input.username"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <v-text-field
                        label="Email" 
                        v-model="input.email"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <v-text-field
                        label="Short Name" 
                        v-model="input.short_name"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <v-text-field
                        label="Full Name" 
                        v-model="input.full_name"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" md="3">
                    <v-switch v-model="input.is_active" label="Is Active"></v-switch>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <v-select
                    item-value="value"
                    name="item"
                    item-text="name"
                    v-model="input.role"
                    :items="role"
                    label="Role"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" md="3">
                    <v-text-field
                        label="Password" 
                        v-model="input.password"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="text-right">
                    <v-btn class="mr-4" color="primary" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>
<script>
	export default {
        props: {
            userId: {
                type: Number
            }
        },
		data() {
            return {
                role: [
                    {value:'SYSADMIN',name: 'System administrator'},
                    {value:'MANAGER',name: 'Manager'},
                    {value:'STAFF',name: 'Staff'},
                    {value:'CUSTOMER',name: 'Customer'},
                    {value:'VENDOR',name: 'Vendor'},
                ],
                userPerm: [
                ],
                success: false,
                input: {
                    id: '',
                    username: '',
                    password: '',
                    is_active: '',
                    role: '',
                    email: '', 
                    short_name: '',
                    full_name: '',
                },
                userByTeam: [],
                userListAvailable: [],
            }
        },
        computed: {
            indexedUser () {
                if (this.userByTeam) {
                    return this.userByTeam.map((item, index) => ({
                        index: index,
                        ...item
                    }))
                }
            },
        },
        methods: {
            // Normal
            form() {
                    console.log('hi')
                if (this.userId) {
                    this.getUser().then((res) => {
                        this.input.id = res.id;
                        this.input.username = res.username;
                        this.input.password = res.password;
                        this.input.is_active = res.is_active;
                        this.input.role = res.role;
                        this.input.email = res.email;
                        this.input.short_name = res.short_name;
                        this.input.full_name = res.full_name;

                        this.teamListAvailable = this.teamList;
                    });
                }
            },
			async getUser() {
                try {
                    this.loading = true
                    const res = await this.$api.user.get(this.userId);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			save() {
                this.saveUser().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'userList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                });
			},
			async saveUser() {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.user.save( this.input );
                    } else {
                        res = await this.$api.user.new( this.input );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
        },
        mounted(){
            this.form();
        }
    }
</script>