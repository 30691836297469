<template>
    <div>
        <v-data-table 
            :loading="this.loading" loading-text="Loading... Please wait"
            :headers="headersAssetPlan"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items="indexedAssetPlans"
            :items-per-page="20"
            :footer-props="{
                itemsPerPageOptions: [20,40,60],
            }" 
            class="full-width">
            <template v-slot:item="row">
                <tr>
                <td>
                    <v-autocomplete ref="internal" 
                        v-model="row.item.asset"
                        cache-items
                        hide-no-data
                        :items="assetListFull"
                         name="item"
                        item-value="id"
                        item-text="name">
                    </v-autocomplete>
                </td>
                <td>
                    <v-select
                    :items="users" 
                    item-value="id"
                    name="item"
                    item-text="username"
                    label="Checker" 
                    v-model="row.item.checker"
                    ></v-select>
                </td>
                <td class="text-left">
                    <v-select
                    :items="users" 
                    item-value="id"
                    name="item"
                    item-text="username"
                    label="Verifier" 
                    v-model="row.item.verifier"
                    @change="verifierSelect(row.item.index, row.item.checker, row.item.verifier)"
                    ></v-select>
                </td>
                <td class="text-left">
                    <v-text-field
                        v-model="row.item.sort_order" 
                        required
                    ></v-text-field>
                </td>
                <td>
                    <v-row no-gutters>
                        <v-tooltip bottom v-if="row.item.valid" class="mr-4" >
                            <template v-slot:activator="{ on }">
                                <v-icon class="mr-2" color="green darken-2" v-on="on">mdi-clipboard-check-outline</v-icon> 
                            </template>
                            <span>
                                This asset plan is valid.
                            </span>
                        </v-tooltip>
                        <v-col cols="auto">
                            <v-btn-toggle>
                                <v-btn small fab v-if="row.item.id" @click="edit(row.item.id)"><v-icon>mdi-pencil</v-icon></v-btn>

                                <v-btn small fab @click="save(row.item.id, row.item.asset, row.item.checker, row.item.verifier, row.item.sort_order)"><v-icon >mdi-content-save</v-icon></v-btn>

                                <v-btn small fab @click="remove(row.item.index, row.item.id)"><v-icon>mdi-delete</v-icon></v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                </td>
                </tr>
            </template>
        </v-data-table>
        <div><v-btn class="mr-4" @click="newAssetPlan()">Add Asset Plan</v-btn></div>
        <v-dialog
        persistent
        v-model="alertCheckerVerifier"
        max-width="290"
        >
        <v-card>
            <v-card-title class="headline">Error!</v-card-title>
            <v-card-text>
            Verifier should not be the same with Checker!
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary darken-1"
                text
                @click="removeVerifier()"
            >
                Okay
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>
    
</template>
<script>
	export default {
        props: {
            checkplanId: {
                type: Number
            },
        },
		data() {
            return {
                headersAssetPlan: [
                { text: 'Asset',  align: 'left', sortable: true, value: 'asset', },
                { text: 'Checker', value: 'checker', width: '200px' },
                { text: 'Verifier', value: 'verifier', width: '200px' },
                { text: 'Order', value: 'sort_order', width:'100px' },
                { text: ''}
                ],
                id: [],
                loading: true,
                users:[],
                assetListFull: [],
                assetPlans: [],
                alertCheckerVerifier: false,
                faultyVerifier: 0,
            }
        },
        computed: {
            indexedAssetPlans () {
                if (this.assetPlans) {
                return this.assetPlans.map((item, index) => ({
                    index: index,
                    ...item
                }))
                }
            },
        },
        methods: {
            list() {
			    this.getRecurranceTimebyCheckplan().then(res => this.assetPlans = res);
            },
			async getRecurranceTimebyCheckplan() {
                try {
                    this.loading = true
                    const res = await this.$api.assetPlan.search( { checkplan:this.checkplanId });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            getAdditionalData() {
			    this.getAssets().then(res => this.assetListFull = res);
			    this.getUsers().then((res) => {
                    this.users = res
                    this.users.unshift({id: null, username: 'None'})
                });
            },
			async getAssets() {
                try {
                    const res = await this.$api.asset.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getUsers() {
                try {
                    const res = await this.$api.user.listNameRef();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getUserName(user_id) {
                if ( this.users.length !== 0 && user_id ) {
                    return this.users.find(user => user.id === user_id).username;
                }
            }, 
            getAssetName(asset_id) {
                if ( this.assetListFull.length !== 0 && asset_id ) {
                    return this.assetListFull.find(asset => asset.id === asset_id).name;
                }
            }, 
            newAssetPlan() {
                this.$router.push({ name: 'assetPlanForm', params: { checkplanId: this.checkplanId } })
            },
            edit(id) {
                this.$router.push({ name: 'assetPlanForm', params: { id: id, checkplanId: this.checkplanId } })
            },
            save(id, asset, checker, verifier, sort_order) {
                this.saveAssetPlan(id, asset, checker, verifier, sort_order).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                            this.updateCheckplan();
                        })
                    }
                });
			},
			async saveAssetPlan(id, asset, checker, verifier, sort_order) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.assetPlan.save( {id: id, asset: asset, checker: checker, verifier: verifier, sort_order: sort_order, checkplan: this.checkplanId });
                    } else {
                        res = await this.$api.assetPlan.new({id: id, asset: asset, checker: checker, verifier: verifier, sort_order: sort_order, checkplan: this.checkplanId });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.assetPlan.delete(id).then((res) => {
                                this.list();
                                this.updateCheckplan();
                            });
                        }
                    })
                } else {
                    this.$delete(this.assetPlans, index)
                }
            },
            updateCheckplan(event){
                this.$emit('updateCheckplan')
            },
            verifierSelect(index, checker, verifier) {
                if (checker && verifier) {
                    if (checker == verifier) {
                        this.alertCheckerVerifier = true;
                        this.faultyVerifier = index;
                    }
                }
            },
            removeVerifier(index) {
                this.assetPlans[this.faultyVerifier].verifier = null;
                this.indexedAssetPlans[this.faultyVerifier].verifier = null;
                this.alertCheckerVerifier = false;
                this.faultyVerifier = 0;
            },
        },
        mounted(){
            this.list();
            this.getAdditionalData();
        }
    }
</script>
