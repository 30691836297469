<template>
    <v-container fluid class="list-level">
        <v-row>
        <v-col cols="12">
            <div class="mb-5 mx-auto text-right">
                <v-btn @click="edit()" dark color="primary">
                    <v-icon>mdi-plus</v-icon>
                    New
                </v-btn>
            </div>
            <v-data-table
            v-if="levelList"
            :loading="loading" loading-text="Loading... Please wait"
            :headers="headers"
            :items="levelList"
            :sort-by="['id']"
            :server-items-length="pagination.count"
            hide-default-footer
            class="elevation-1"
            >
            <template v-slot:item="row">
              <tr>
                <td class="text-left">{{row.item.id}}</td>
                <td class="text-left">{{row.item.name}}</td>
                <td class="text-left" >
                   <span v-if="row.item.level_one">{{ getLevel1Name(row.item.level_one) }} <span v-if="row.item.level_two">-</span> {{ getLevel2Name(row.item.level_two) }} <span v-if="row.item.level_three">-</span>  {{ getLevel3Name(row.item.level_three) }} <span v-if="row.item.level_four">-</span>  {{ getLevel4Name(row.item.level_four) }}</span> 
                </td>
                <td style="width:150px" class="text-xs-center">
                    <v-btn-toggle>
                        <v-btn small fab icon color="grey"  @click="edit(row.item.id)">
                        <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn small fab icon color="grey" @click="remove(row.item.id)" >
                        <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center">
              <v-pagination total-visible="7" class="mt-2 mb-2" v-model="pagination.current" :length="pagination.pages"></v-pagination>
              <div class="subtitle">Total: {{pagination.count}}</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
	export default {
        components: {
        },
		data: () => ({
            headers: [
            { text: 'ID', align: 'left', sortable: true, value: 'id', width: 100},
            { text: 'Name', value: 'name' },
            { text: '', value: '' },
            { text: '', sortable: false,}
            ],
            pagination:{
                count:0,
                pages: 1,
                current:1,
            },
            perPage: 20,
            success: false,
            levelSelected: 1,
            levelList: [],
            level1List: [],
            level2List: [],
            level3List: [],
            level4List: [],
            level5List: [],
            loading: true,
            loadingLevels: true,
		}),
        props: {
            globalPref: {
                type: Array,
                required: false
            }
        },
        computed: {
			maxLevel: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'maximum_level').value);
				} else {
                    return 5;
                }
			},
			level1Label: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'level_one_label').value);
				} else {
                    return 'Level 1';
                }
			},
			level2Label: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'level_two_label').value);
				} else {
                    return 'Level 2';
                }
			},
			level3Label: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'level_three_label').value);
				} else {
                    return 'Level 3';
                }
			},
			level4Label: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'level_four_label').value);
				} else {
                    return 'Level 4';
                }
			},
			level5Label: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'level_five_label').value);
				} else {
                    return 'Level 5';
                }
			}
        },
		methods: {
            // Main Function
            list() {
			    this.getLevel(this.levelSelected, 1).then((res) => {
                    this.levelList = res.results;
                    // console.log(res.results)
                    this.pagination.next = res.next
                    this.pagination.count = res.count
                    this.pagination.pages = Math.ceil(res.count / this.perPage)
                });
            },
			async getLevel(level, page) {
                try {
                    this.loading = true
                    const res = await this.$api.level.search(level,  {limit:this.perPage, offset: this.perPage*(page - 1) });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            getLevelLists() {
                if (this.levelSelected !== 1 ) {
                    this.getLevels(1).then(res => this.level1List = res);
                }
                if (this.maxLevel >= 2 && this.levelSelected !== 2 ) {
			        this.getLevels(2).then(res => this.level2List = res);
                }
                if (this.maxLevel >= 3 && this.levelSelected !== 3 ) {
			        this.getLevels(3).then(res => this.level4List = res);
                }
                if (this.maxLevel >= 4 && this.levelSelected !== 4 ) {
			        this.getLevels(4).then(res => this.level4List = res);
                }
                if (this.maxLevel >= 5 && this.levelSelected !== 5 ) {
			        this.getLevels(5).then(res => this.level5List = res);
                }
            },
			async getLevels(level) {
                try {
                    this.loadingLevels = true
                    const res = await this.$api.level.list(level);
                    return res.data
                } catch (error) {
                    // handle error
                } finally {
                    this.loadingLevels = false
                }
            },
            getLevel1Name(id) {
                if ( this.level1List && this.level1List.length !== 0 && id ) {
                    return this.level1List.find(level1 => level1.id === id).name;
                } else {
                    return id;
                }
            },
            getLevel2Name(id) {
                if (this.level2List && this.level2List.length !== 0 && id ) {
                    return this.level2List.find(level2 => level2.id === id).name;
                } else {
                    return id;
                }
            },
            getLevel3Name(id) {
                if (this.level3List && this.level3List.length !== 0 && id ) {
                    return this.level3List.find(level3 => level3.id === id).name;
                } else {
                    return id;
                }
            },
            getLevel4Name(id) {
                if (this.level4List && this.level4List.length !== 0 && id ) {
                    return this.level4List.find(level4 => level4.id === id).name;
                } else {
                    return id;
                }
            },
            getLevel5Name(id) {
                if (this.level5List &&  this.level5List.length !== 0 && id ) {
                    return this.level5List.find(level5 => level5.id === id).name;
                } else {
                    return id;
                }
            },
			edit(id) {
                this.$router.push({ name: 'levelForm', params: { id , lvl: this.levelSelected } })
            },
            remove(id) {
                this.$swal({
                    icon: 'warning',
                    title: 'Are you sure to delete this?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
			            this.$api.level.delete(this.levelSelected, id).then(res => this.list() );
                    }
                })
            }
		},
		mounted () {
            this.levelSelected = this.$router.currentRoute.params.lvl
			this.list();
            this.getLevelLists();
            this.success = true;
		},
        beforeRouteUpdate (to, from, next) {
            this.levelSelected = to.params.lvl
            this.list();
            this.getLevelLists();
            this.success = true;
            next()
        },
        watch: {
            pagination: {
                handler(val){
                    this.getLevel(this.levelSelected, this.pagination.current).then((res) => {
                        this.levelList = res.results;
                    });
                },
                deep: true
            },
        },
	}
</script>