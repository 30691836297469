<template>
    <v-container fluid class="list-unit">
      <v-row>
        <v-col cols="12">
            <div class="py-2 px-4 text-left command-button-list">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon>mdi-arrow-left</v-icon>
                    Back
                </v-btn>
            </div>
            <v-card class="pa-3 mb-3 mx-auto text-right">
                <unitForm :unitId="id" v-on:return-form="quitEdit"></unitForm>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import unitForm from '@/components/form/unit-form.vue';
	export default {
        components: {
            unitForm
        },
        props: {
            id: Number   
        },
		data: () => ({
		}),
		methods: {
            // Main Function
			quitEdit() {
                this.$router.push({ name: 'unitList' })
            },
		},
		mounted () {
		},
	}
</script>