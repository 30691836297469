<template>
    <div>
            <v-data-table 
            :headers="headersUser"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items="indexedUser"
            :items-per-page="20"
            :footer-props="{
                itemsPerPageOptions: [20,40,60],
            }" 
            class="full-width">
            <template v-slot:item="row">
                <tr>
                <td class="text-left">
                    <span v-if="row.item.id && !loading">{{ getUserName(row.item.user) }}</span>
                    <v-select v-if="!row.item.id"
                    item-value="id"
                    name="username"
                    item-text="username"
                    v-model="row.item.user"
                    :items="userList"
                    label="User"
                    ></v-select>
                    </td>
                <td>
                    <v-btn v-if="!row.item.id" class="mr-4" @click="save(row.item.id, row.item.user)"><v-icon class="mr-2">mdi-content-save</v-icon> Save</v-btn>
                    <v-btn text class="mr-4" @click="remove(row.item.index, row.item.id)"><v-icon>mdi-delete</v-icon></v-btn>
                </td>
                </tr>
            </template>
        </v-data-table>
        <v-btn class="mr-4" @click="addMember()">Add User</v-btn>
    </div>
</template>
<script>
	export default {
        props: {
            teamList: {
               type: Array
            },
            teamId: {
                type: Number
            },
            userId: {
                type: Number
            }
        },
		data() {
            return {
                headersUser: [
                    { text: 'User',  align: 'left', sortable: true, value: 'id', },
                    { text: ''}
                ],
                userByTeam: [],
                userList: [],
                loading: true
            }
        },
        computed: {
            indexedUser () {
                if (this.userByTeam) {
                    return this.userByTeam.map((item, index) => ({
                        index: index,
                        ...item
                    }))
                }
            },
        },
        methods: {
            list() {
			    this.getUserByTeam().then(res => this.userByTeam = res);
            },
			async getUserByTeam() {
                try {
                    this.loading = true
                    const res = await this.$api.member.search({ team: this.teamId });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            getUserName(id) {
                if ( this.userByTeam.length !== 0 && id ) {
                    return this.userList.find(user => user.id === id).username;
                }
            }, 
            addMember() {
                this.userByTeam.push({id: '', team: '', user: ''});
            },
            save(id, user) {
                this.saveMember(id, this.teamId, user).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            reverseButtons: true,
                            confirmButtonText: 'OK',
                        }).then((result) => {
                        })
                        this.list();
                    }
                });
			},
			async saveMember(id, teamId, user) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.member.save( {id: id, user: user, team: teamId});
                    } else {
                        res = await this.$api.member.new({user: user, team: teamId });
                    }
                    return res;
                } catch (error) {
                    // handle error
                    console.log(error)
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
			                this.$api.member.delete(id).then(res => this.list() );
                        }
                    })
                } else {
                    this.$delete(this.userByTeam, index)
                }
            },
            getUserList() {
			    this.getUsers().then(res => this.userList = res);
            },
			async getUsers() {
                try {
                    const res = await this.$api.user.listNameRef();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
        },
        mounted(){
            this.list();
            this.getUserList();
        }
    }
</script>