<template>
    <v-container fluid class="pa-0 list-checklist">
      <v-row>
        <v-col cols="12" class="pa-0 pa-sm-2">
            <v-card class="pa-3 mb-3 mx-auto text-left">
                <v-form lazy-validation
                ref="form"
                v-model="valid">
                    <v-card-text class="d-flex flex-wrap align-center">
                        <v-img class="mr-2" contain v-if="this.checkplanList" :src="getCheckplanIcon(info.checkplan) || icongraphicPlaceholder" max-width="70" width="70" height="70"></v-img>
                        <v-col xs="auto" class="pt-0 pb-0">
                            <div class="text--primary">
                                {{info.name}}
                            </div>
                            <div class="text--primary">
                                Due: {{objCheckerDueDateTime}}
                            </div>
                        </v-col>
                    </v-card-text>
                    <v-row v-if="input.description">
                        <v-col cols="12" sm="12" md="12">
                            <v-textarea
                            outlined
                            readonly
                            name="input-7-4"
                            label="Description"
                            :value="input.description"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <assetCheckList 
                                v-if="input.id" 
                                :checkverifyStatus="checkverifyStatus" 
                                :checklistId="input.id" 
                                :profileId="profileId"
                                :assetCheckList="info.assetCheckList"
                            ></assetCheckList>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import assetCheckList from '@/components/check/asset-check-list.vue';
	export default {
		  components: {
              assetCheckList
          },
          props: {
                id: Number,
                globalPref: {
                    type: Array,
                    required: false
                },
                profileId:{},
                checkplanList: Array,
          },
		data: () => ({
            valid: true,
			success: false,
            loading: true,
            input: {
                id: '',
                description: '',
            },
            info: {
                name: '',
                status: '',
                open_dt: '',
                check_due_dt: '',
                verify_due_dt: '',
                good_count_target:0 ,
                check_good_count:0 ,
                verify_good_count:0 ,
                check_good_countPercent:0 ,
                verify_good_countPercent:0 ,
                checker_data_complete: false,
                verifier_data_complete: false,
                checker:[],
                verifier:[],
                assetCheckList:[],
            },
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 50) || 'Name must be less than 50 characters',
            ],
        }),
		computed: {
            objOpenDateTime: {
                get(){
                    return (new Date(this.info.open_dt))
                },
                set(value) {
                    this.info.open_dt = value
                }
            },
            objCheckerDueDateTime: {
                get(){
                    return this.$moment(new Date(this.info.check_due_dt)).format('MMMM Do YYYY, h:mm:ss a')
                },
                set(value) {
                    this.info.check_due_dt = value
                }
            },
            objVerifierDueDateTime: {
                get(){
                    return (new Date(this.info.verify_due_dt))
                },
                set(value) {
                    this.info.verify_due_dt = value
                }
            },
            checkverifyStatus: function() {
                if(this.info.checker_data_complete == true) {
                    if(this.info.verifier_data_complete == false) {
                        return 'CHECKED';
                    } else {
                        return 'VERIFIED'
                    }
                } else {
                    return null
                }
            },
        },
		methods: {
            // Main Function
            form() {
                if (this.id) {
                    this.getChecklist().then((res) => {
                        this.input.id = res.id;
                        this.info.name = res.name;
                        this.info.status = res.status;
                        this.input.description = res.description;
                        this.info.open_dt = res.open_dt;
                        this.info.checkplan = res.checkplan;
                        this.info.check_due_dt = res.check_due_dt;
                        this.info.verify_due_dt = res.verify_due_dt;
                        this.info.good_count_target = res.good_count_target;
                        this.info.check_good_count = res.check_good_count;
                        this.info.verify_good_count = res.verify_good_count;
                        this.info.check_good_countPercent = res.check_good_percent;
                        this.info.verify_good_countPercent = res.verify_good_percent;
                        this.info.checker_data_complete = res.checker_data_complete
                        this.info.verifier_data_complete = res.verifier_data_complete
                        this.info.checker = res.checker_set;
                        this.info.verifier = res.verifier_set;
                        this.info.assetCheckList = res.asset_check_set;
                    });
                }
            },
			async getChecklist() {
                try {
                    this.loading = true
                    const res = await this.$api.checklist.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			save() {
                this.saveChecklist().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'checkVerifyList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                });
			},
			async saveChecklist() {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.checklist.save( this.input );
                    } else {
                        res = await this.$api.checklist.new( this.input );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			quitEdit() {
                this.$router.push({ name: 'checkVerifyList' })
            },
            getLevel1List() {
                this.getLevel1(1).then(res => this.level1List = res);
            },
			async getLevel1() {
                try {
                    this.loading = true
                    const res = await this.$api.level.list(1);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            getLevelSelect(level, parent) {
                this.getLevel(level, parent).then((res) => {
                    if (level == '2') {
                        this.level2List = res.filter(level2 => level2.level_one === parent);
                    }
                    if (level == '3') {
                        this.level3List = res.filter(level3 => level3.level_one === parent);
                    }
                    if (level == '4') {
                        this.level4List = res.filter(level4 => level4.level_one === parent);
                    }
                    if (level == '5') {
                        this.level5List = res.filter(level5 => level5.level_one === parent);
                    }
                });
            },
			async getLevel(level, parent) {
                try {
                    this.loading = true
                    const res = await this.$api.level.list(level);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            getCheckplanIcon(checkplan_id) {
                if ( this.checkplanList.length !== 0 && checkplan_id ) {
                    return this.checkplanList.find(checkplan => checkplan.id === checkplan_id).icongraphic;
                }
            }, 
		},
		mounted () {
            this.form();
            this.success = true;
		},
	}
</script>