<template>
    <div>
        <v-data-table 
            :loading="this.loading" loading-text="Loading... Please wait"
            :headers="headersMastComp"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items="indexedMastComps"
            :server-items-length="pagination.count"
            hide-default-footer
            class="full-width">
            <template v-slot:item="row">
                <tr>
                <td>
                    <v-text-field
                        v-model="row.item.name" 
                        :rules="nameRules"
                        :counter="50"
                    ></v-text-field>
                </td>
                <td class="text-left">
                    <v-text-field
                        v-model="row.item.abbreviation" 
                        :rules="abbreRules"
                        :counter="5"
                    ></v-text-field>
                </td>
                <td>
                    <v-btn-toggle>
                    <v-btn small fab @click="save(row.item.id, row.item.name, row.item.abbreviation, row.item.system)"><v-icon>mdi-content-save</v-icon></v-btn>
                    <v-btn small fab @click="remove(row.item.index, row.item.id)"><v-icon>mdi-delete</v-icon></v-btn>
                    </v-btn-toggle>
                </td>
                </tr>
            </template>
        </v-data-table>
          <div class="text-center">
              <v-pagination class="mt-2 mb-2" v-model="pagination.current" :length="pagination.pages"></v-pagination>
              <div class="subtitle">Total: {{pagination.count}}</div>
          </div>
        <div>
        <p class="caption font-weight-bold">Please save before adding new.</p>
        <v-btn class="mr-4" @click="newMastComp()">Add Master Component</v-btn></div>
    </div>
</template>
<script>
	export default {
        props: {
            systemId: {
                type: Number
            }
        },
		data: () => ({
                loading: true,
                headersMastComp: [
                { text: 'Name', align: 'left',  sortable: true, value: 'name', },
                { text: 'Abbreviation', value: 'abbreviation' },
                { text: ''}
                ],
                
                pagination:{
                    count:0,
                    pages: 1,
                    current:1,
                },
                perPage: 20,
                mastCompList: [],
                nameRules: [
                    v => !!v || 'Name is required',
                    v => (v && v.length <= 50) || 'Name must be less than 50 characters',
                ],
                abbreRules: [
                    v => !!v || 'Abbreviation is required',
                    v => (v && v.length <= 5) || 'Abbreviation must be less than 5 characters',
                ],
        }),
        computed: {
            indexedMastComps () {
                return this.mastCompList.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
            list() {
			    this.getMastComps(1).then((res) => {
                    this.mastCompList = res.results;
                    this.pagination.next = res.next
                    this.pagination.count = res.count
                    this.pagination.pages = Math.ceil(res.count / this.perPage)
                });
            },
			async getMastComps(page) {
                try {
                    this.loading = true
                    const res = await this.$api.mastComp.search({ system: this.systemId, limit:this.perPage, offset: this.perPage*(page - 1) })
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            save(id, name, abbreviation, system) {
                this.saveMastComp(id, name, abbreviation, system).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            reverseButtons: true,
                            confirmButtonText: 'OK',
                        }).then((result) => {
                        })
                        this.list();
                    }
                });
			},
			async saveMastComp(id, name, abbreviation, system) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.mastComp.save( {id: id, name: name, abbreviation: abbreviation, system: system });
                    } else {
                        res = await this.$api.mastComp.new({name: name, abbreviation: abbreviation, system: system });
                    }
                    return res;
                } catch (error) {
                    // handle error
                    console.log(error)
                } finally {
                    this.saving = false
                }
            },
            newMastComp() {
                this.mastCompList.push({id: '', name: '', abbreviation: '', system: this.systemId});
            },
            remove(index, id) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
			                this.$api.mastComp.delete(id).then(res => this.list() );
                        }
                    })
                } else {
                    this.$delete(this.mastCompList, index)
                }
            },
        },
        mounted(){
            this.list();
        },
        watch: {
            pagination: {
                handler(val){
                    this.getMastComps(this.pagination.current).then((res) => {
                        this.mastCompList = res.results;
                    });
                },
                deep: true
            },
        },
    }
</script>