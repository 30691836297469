<template>
    <v-container fluid class="list-checklist">
      <v-row>
        <v-col cols="12">
            <div class="py-2 px-4 text-left command-button-list">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon>mdi-arrow-left</v-icon>
                    Back
                </v-btn>
            </div>
            <v-card class="pa-3 mb-3 mx-auto text-left">
                <v-tabs >
                    <v-tabs-slider />
                    <v-tab>Checkplan Info</v-tab>
                    <v-tab v-if="this.input.id">Child: Asset Plan</v-tab>
                    <v-tab v-if="this.input.id">Child: Recurrance Time</v-tab>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                    label="Name" 
                                    v-model="input.name"
                                ></v-text-field>
                            </v-col>
                            <!-- <v-col cols="12" sm="12" md="12">
                                <div class="caption">Status</div>
                                <v-switch inset v-model="inputStatus" label="Status"></v-switch>
                            </v-col> -->
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea
                                    outlined
                                    label="Description" 
                                    v-model="input.description"
                                    hide-details
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row v-if="inputRecurrence">
                            <v-col v-if="inputRecurrence.length > 0">
                                Recurrence:
                                <ul>
                                    <li v-for="(recur,index) in inputRecurrence" v-bind:key="index">
                                        {{ getRecurName(recur)}}
                                        {{(recur.recurrence)}}
                                    </li>
                                </ul>
                                {{  }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="3">
                                <label>Icongraphic</label><br/>
                                <v-img v-if="input.icongraphic" :src="input.icongraphic" width="180" height="180"></v-img>
                                <v-img v-if="!input.icongraphic" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                            </v-col>
                            <v-col cols="6" md="3">
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    accept="image/png, image/jpeg"
                                    placeholder="Upload"
                                    prepend-icon="mdi-camera"
                                    label="Add or Replace Icongraphic"
                                    v-model="newIcon"
                                ></v-file-input>
                            </v-col>
                        </v-row><br/>
                        <v-row>
                            <v-col class="md-2">
                                <small v-if="info.file_1">Uploaded: <a :href="info.file_1" target="_blank">{{info.file_1}}</a></small>
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    placeholder="Upload"
                                    prepend-icon="mdi-file"
                                    label="File 1"
                                    v-model="input.newfile_1"
                                ></v-file-input>
                            </v-col>
                            <v-col class="md-2">
                                 <small v-if="info.file_2">Uploaded: <a :href="info.file_2" target="_blank">{{info.file_2}}</a></small>
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    placeholder="Upload"
                                    prepend-icon="mdi-file"
                                    label="File 2"
                                    v-model="input.newfile_2"
                                ></v-file-input>
                            </v-col>
                            <v-col class="md-2">
                                <small v-if="info.file_3">Uploaded: <a :href="info.file_3" target="_blank">{{info.file_3}}</a></small>
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    placeholder="Upload"
                                    prepend-icon="mdi-file"
                                    label="File 3"
                                    v-model="input.newfile_3"
                                ></v-file-input>
                            </v-col>
                            <v-col class="md-2">
                                <small v-if="info.file_4">Uploaded: <a :href="info.file_4" target="_blank">{{info.file_4}}</a></small>
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    placeholder="Upload"
                                    prepend-icon="mdi-file"
                                    label="File 4"
                                    v-model="input.newfile_4"
                                ></v-file-input>
                            </v-col>
                            <v-col class="md-2">
                                <small v-if="info.file_5">Uploaded: <a :href="info.file_5" target="_blank">{{info.file_5}}</a></small>
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    placeholder="Upload"
                                    prepend-icon="mdi-file"
                                    label="File 5"
                                    v-model="input.newfile_5"
                                ></v-file-input>
                            </v-col>
                            <v-col class="md-2">
                                <small v-if="info.file_6">Uploaded: <a :href="info.file_6" target="_blank">{{info.file_6}}</a></small>
                                <v-file-input
                                    ref="file"
                                    :rules="iconRules"
                                    placeholder="Upload"
                                    prepend-icon="mdi-file"
                                    label="File 6"
                                    v-model="input.newfile_6"
                                ></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="text-right">
                                <v-tooltip bottom v-if="valid" class="mr-4" >
                                <template v-slot:activator="{ on }">
                                    <v-icon v-if="valid" class="mr-2" color="green darken-2" v-on="on">mdi-clipboard-check-outline</v-icon> 
                                </template>
                                <span>
                                    This checkplan is valid.
                                </span>
                                </v-tooltip>
                                <v-btn class="mr-4" color="primary" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12">
                            <assetPlanTable v-on:updateCheckplan="refreshCheckPlan" :checkplanId="input.id"></assetPlanTable>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12">
                            <recurranceTimeTable v-on:updateCheckplan="refreshCheckPlan" :checkplanId="input.id" :recurrenceList="recurrenceList"></recurranceTimeTable>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import assetPlanTable from '@/components/table/asset-plan-table.vue';
import recurranceTimeTable from '@/components/table/recurrance-time-table.vue';
	export default {
		  components: {
              assetPlanTable,
              recurranceTimeTable
          },
          props: {
                id: Number,
          },
		data: () => ({
			success: false,
			loading: true,
            input:{
                id: '',
                name: '',
                description: '',
                checker: '',
                verifier: '',
                icongraphic: null,
                newfile_1: null,
                newfile_2: null,
                newfile_3: null,
                newfile_4: null,
                newfile_5: null,
                newfile_6: null,
            },
            info: {
                file_1: null,
                file_2: null,
                file_3: null,
                file_4: null,
                file_5: null,
                file_6: null,
            },
            inputAssetPlan: [],
            inputRecurrence: [],
            recurranceTimeList:[],
            recurrenceList: [],
            valid: false,
            iconRules: [
                v => !v || v.size < 2000000 || 'Avatar size should be less than 2 MB!',
            ],
            newIcon: null
        }),
        computed: {
            userVerifier: function() {
                if (this.users.length > 0) {
                    if (this.input.checker == null) {
                        return (this.users);
                    } else {
                        return (this.users.filter(users => users.id !== this.input.checker));
                    }
                }
            },
        },
		methods: {
            // Main Function
			form() {
                if (this.id) {
                    this.getCheckplan().then((res) => {
                        this.input.id = res.id;
                        this.input.name = res.name;
                        this.input.icongraphic = res.icongraphic;
                        this.input.description = res.description;
                        this.input.checker = res.checker;
                        this.input.verifier = res.verifier;
                        this.inputAssetPlan = res.asset_plan_set;
                        this.inputRecurrence = res.recurrence_set;
                        this.info.file_1 = res.file_1;
                        this.info.file_2 = res.file_2;
                        this.info.file_3 = res.file_3;
                        this.info.file_4 = res.file_4;
                        this.info.file_5 = res.file_5;
                        this.info.file_6 = res.file_6;

                        this.getAdditionalData();
                    });
                }
            },
			async getCheckplan() {
                try {
                    this.loading = true
                    const res = await this.$api.checkplan.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			save() {
                this.saveCheckplan().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'checkplanList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                })
			},
			async saveCheckplan() {
                try {
                    let res;
                    this.saving = true
                    let formData = new FormData();

                    formData.append("id", this.input.id);
                    formData.append("name", this.input.name);
                    formData.append("description", this.input.description);
                    if (this.newIcon) {
                        formData.append("icongraphic", this.newIcon);
                    }
                    if (this.input.newfile_1) {
                        formData.append("file_1", this.input.newfile_1);
                    }
                    if (this.input.newfile_2) {
                        formData.append("file_2", this.input.newfile_2);
                    }
                    if (this.input.newfile_3) {
                        formData.append("file_3", this.input.newfile_3);
                    }
                    if (this.input.newfile_4) {
                        formData.append("file_4", this.input.newfile_4);
                    }
                    if (this.input.newfile_5) {
                        formData.append("file_5", this.input.newfile_5);
                    }
                    if (this.input.newfile_6) {
                        formData.append("file_6", this.input.newfile_6);
                    }
                    if (this.input.id) {
                        res = await this.$api.checkplan.saveForm( this.input.id, formData );
                    } else {
                        res = await this.$api.checkplan.new( formData );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			quitEdit() {
                this.$router.push({ name: 'checkplanList' })
            },
            getAdditionalData() {
			    this.getRecurranceTimebyCheckplan().then(res => this.recurranceTimeList = res);
			    this.getRecurrences().then(res => this.recurrenceList = res);
            },
			async getRecurranceTimebyCheckplan() {
                try {
                    const res = await this.$api.recurrenceTime.search({ checkplan: this.input.id, is_active: true });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getRecurrences() {
                try {
                    const res = await this.$api.recurrence.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getRecurName(id) {
                if ( this.recurrenceList.length !== 0 && id ) {
                    return this.recurrenceList.find(recurrance => recurrance.id === id).name;
                }
            },
            refreshCheckPlan() {
                this.form();
                this.getAdditionalData();
            }
		},
		mounted () {
            this.form();
            this.getAdditionalData();
            this.valid = true;
		},
	}
</script>