<template>
    <div class="pa-5">
        <v-form @submit.prevent="filter()">
            <v-text-field
                label="Name" 
                v-model="filterFields.name"
            ></v-text-field>
            <v-autocomplete ref="internal" 
                v-model="filterFields.base_asset_system"
                cache-items
                hide-no-data
                :items="systemListSelect"
                name="item"
                item-value="id"
                label="System" 
                item-text="name">
            </v-autocomplete>
            <v-autocomplete ref="internal" 
                v-model="filterFields.base_asset"
                cache-items
                hide-no-data
                :items="baseAssetListSelect"
                name="item"
                item-value="id"
                label="Base Asset" 
                item-text="name">
            </v-autocomplete>
            <v-select
            :items="condition" 
            item-value="value"
            name="item"
            item-text="name"
            label="Condition" 
            v-model="filterFields.condition"
            ></v-select>
            <v-autocomplete ref="internal" 
                v-model="filterFields.level1"
                cache-items
                hide-no-data
                :items="level1List"
                name="item"
                item-value="id"
                :label="level1Label" 
                item-text="name">
            </v-autocomplete>
            <v-autocomplete ref="internal" 
                v-if="this.maxLevel >=2"
                v-model="filterFields.level2"
                cache-items
                hide-no-data
                :items="level2List"
                name="item"
                item-value="id"
                :label="level2Label" 
                item-text="name">
            </v-autocomplete>
            <v-autocomplete ref="internal" 
                v-if="this.maxLevel >=3"
                v-model="filterFields.level3"
                cache-items
                hide-no-data
                :items="level5List"
                name="item"
                item-value="id"
                :label="level3Label" 
                item-text="name">
            </v-autocomplete>
            <v-autocomplete ref="internal" 
                v-if="this.maxLevel >=4"
                v-model="filterFields.level4"
                cache-items
                hide-no-data
                :items="level4List"
                name="item"
                item-value="id"
                :label="level4Label" 
                item-text="name">
            </v-autocomplete>
            <v-autocomplete ref="internal" 
                v-if="this.maxLevel >=5"
                v-model="filterFields.level5"
                cache-items
                hide-no-data
                :items="level5List"
                name="item"
                item-value="id"
                :label="level5Label" 
                item-text="name">
            </v-autocomplete>
            <v-btn color="primary" type="submit">Filter</v-btn>
        </v-form>
    </div>
</template>
<script>
	export default {
		  components: {
          },
		data: () => ({
            baseAssetListSelect:[],
            condition: [
                {
                    name: 'All Condition',
                    value: ''
                }, 
                {
                    name: 'Good',
                    value: 'good'
                }, 
                {
                    name: 'Partial',
                    value: 'partial'
                }, 
                {
                    name: 'Bad',
                    value: 'bad'
                }, 
                {
                    name: 'Misc',
                    value: 'misc'
                },
            ],
            level1ListSelect: [],
            level2ListSelect: [],
            level3ListSelect: [],
            level4ListSelect: [],
            level5ListSelect: [],
			systemListSelect: [],
        }),
        props: {
            globalPref: {
                type: Array,
                required: false
            },
            filterFields: {
                type: Object,
            },
            baseAssetList: {
                type: Array,
                required: false
            },
            level1List: {
                type: Array,
                required: false
            },
            level2List: {
                type: Array,
                required: false
            },
            level3List: {
                type: Array,
                required: false
            },
            level4List: {
                type: Array,
                required: false
            },
            level5List: {
                type: Array,
                required: false
            },
        },
        computed: {
			maxLevel: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'maximum_level').value);
				} else {
                    return 5;
                }
            },
            level1Label: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'level_one_label').value);
				} else {
                    return 'Level 1';
                }
			},
			level2Label: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'level_two_label').value);
				} else {
                    return 'Level 2';
                }
			},
			level3Label: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'level_three_label').value);
				} else {
                    return 'Level 3';
                }
			},
			level4Label: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'level_four_label').value);
				} else {
                    return 'Level 4';
                }
			},
			level5Label: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'level_five_label').value);
				} else {
                    return 'Level 5';
                }
			}
        },
        methods: {
            filter(event){
                this.$emit('filteredData', this.filterFields)
            },
            getSystemList() {
			    this.getSystems().then(res => this.systemListSelect = res);
            },
			async getSystems() {
                try {
                    this.loading = true
                    const res = await this.$api.system.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
        },
        mounted() {
            this.getSystemList();
            this.baseAssetListSelect = this.baseAssetList
            this.baseAssetListSelect.unshift({
                id:'',
                name: 'All Base Assets',
            });
            this.level1ListSelect = this.level1List
            this.level2ListSelect = this.level2List
            this.level3ListSelect = this.level3List
            this.level4ListSelect = this.level4List
            this.level5ListSelect = this.level5List
            this.level1ListSelect.unshift({
                id:'',
                name: 'All Location',
            });
            this.level2ListSelect.unshift({
                id:'',
                name: 'All Location',
            });
            this.level3ListSelect.unshift({
                id:'',
                name: 'All Location',
            });
            this.level4ListSelect.unshift({
                id:'',
                name: 'All Location',
            });
            this.level5ListSelect.unshift({
                id:'',
                name: 'All Location',
            });
        }
    }
</script>