<template>
    <div>
        <div>
            <v-data-table 
                :headers="headersBaseParameters"
                :sort-by="['id']"
                :sort-desc="[true]"
                :items="indexedBaseParameter"
                :items-per-page="20"
                hide-default-footer
                :footer-props="{
                    itemsPerPageOptions: [20,40,60],
                }" 
                class="full-width">
                <template v-slot:item="row">
                    <tr>
                    <td class="text-left">
                        <v-select
                        item-value="id"
                        name="name"
                        item-text="name"
                        v-model="row.item.master_parameter"
                        :items="masterParameterList"
                        label="Master Parameter"
                        ></v-select>
                    </td>
                    <td class="text-left">
                        <v-select
                        item-value="id"
                        name="name"
                        item-text="name"
                        v-model="row.item.unit"
                        :items="unitList"
                        label="Unit"
                        ></v-select>
                    </td>
                    <td class="text-left">
                        <v-select
                        item-value="value"
                        name="text"
                        item-text="text"
                        v-model="row.item.condition_mode"
                        :items="conditions"
                        label="Condition Mode"
                        ></v-select>
                    </td>
                    <td class="text-left">
                        <v-select
                        item-value="value"
                        name="text"
                        item-text="text"
                        v-model="row.item.automatic_mode"
                        :items="auto"
                        label="Auto Mode"
                        ></v-select>
                        <div class="d-flex align-center">
                        <v-text-field
                            label="Lower Limit"
                            v-model="row.item.lower_limit" 
                            required
                        ></v-text-field>
                        <span class="ml-2 mr-2">-</span>
                        <v-text-field
                            label="Upper Limit"
                            v-model="row.item.upper_limit" 
                            required
                        ></v-text-field>
                        </div>
                    </td>
                    <td>

                        <v-btn-toggle>
                            <v-btn small fab @click="save(row.item.id, row.item.master_parameter, row.item.unit, row.item.condition_mode, row.item.automatic_mode, row.item.lower_limit, row.item.upper_limit)">
                                <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                            <v-tooltip bottom v-if="row.item.parameter_set" >
                            <template v-slot:activator="{ on }">
                                <v-icon color="grey" dark v-on="on">mdi-delete</v-icon>
                            </template>
                            <span>These assets have to be removed first: 
                                <ul class="text-left">
                                    <li v-for="(asset, index) in row.item.parameter_set" v-bind:key="index">
                                        {{ getAssetName(asset) }} ( ID: {{ asset }} )
                                    </li>    
                                </ul> 
                            </span>
                            </v-tooltip>
                            <v-btn small fab v-if="!row.item.parameter_set" @click="remove(row.item.index, row.item.id)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                        
                        
                    </td>
                    </tr>
                </template>
            </v-data-table>
            <div><v-btn class="mr-4" @click="newBaseParameter()">Add Base Parameter</v-btn></div>
        </div>
    </div> 
</template>
<script>
	export default {
        props: {
            baseAssetId: {
                type: Number
            },
        },
		data() {
            return {
                headersBaseParameters: [
                { text: 'Master Parameter', value: 'master_parameter' },
                { text: 'Unit', value: 'unit' },
                { text: 'Condition Mode', value:'condition'},
                { text: 'Auto Mode', value:'auto'},
                { text: '', value:''},
                ],
                conditions: [
                { text: 'manual', value: 'MANUAL' },
                { text: 'automatic', value: 'AUTOMATIC' },
                ],
                auto: [
                { text: 'exist', value: 'EXIST' },
                { text: 'lower', value: 'LOWER' },
                { text: 'upper', value: 'UPPER' },
                { text: 'range', value: 'RANGE' },
                ],
                baseParameterList: [],
                masterParameterList: [],
                unitList: [],
                assetList: [],
            }
        },
        computed: {
            indexedBaseParameter () {
                return this.baseParameterList.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
            list() {
			    this.getBaseParameterbyBaseAsset().then(res => this.baseParameterList = res);
            },
			async getBaseParameterbyBaseAsset() {
                try {
                    const res = await this.$api.baseParam.search({ base_asset: this.baseAssetId });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            newBaseParameter() {
                this.baseParameterList.push({id: '', base_asset: '', attribute: '', unit: ''});
            },
            save(id, master_parameter, unit, condition_mode, automatic_mode, lower_limit, upper_limit) {
                this.saveBaseParam(id, master_parameter, unit, condition_mode, automatic_mode, lower_limit, upper_limit).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            reverseButtons: true,
                            confirmButtonText: 'OK',
                        }).then((result) => {
                        })
                        this.list();
                    }
                });
			},
			async saveBaseParam(id, master_parameter, unit, condition_mode, automatic_mode, lower_limit, upper_limit) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        console.log(this.baseAssetId)
                        res = await this.$api.baseParam.save( {id: id, master_parameter: master_parameter, unit: unit, condition_mode:condition_mode, automatic_mode:automatic_mode, lower_limit:lower_limit, upper_limit:upper_limit, base_asset: this.baseAssetId });
                    } else {
                        res = await this.$api.baseParam.new({master_parameter: master_parameter, unit: unit, condition_mode:condition_mode, automatic_mode:automatic_mode, lower_limit:lower_limit, upper_limit:upper_limit, base_asset: this.baseAssetId });
                    }
                    return res;
                } catch (error) {
                    // handle error
                    console.log(error)
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
			                this.$api.baseParam.delete(id).then(res => this.list() );
                        }
                    })
                } else {
                    this.$delete(this.baseParameterList, index)
                }
            },
            // Additional
            getAdditionalData() {
			    this.getMasterParams().then(res => this.masterParameterList = res);
			    this.getUnits().then(res => this.unitList = res);
			    this.getAssets().then(res => this.assetList = res);
            },
			async getMasterParams() {
                try {
                    const res = await this.$api.mastParam.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getUnits() {
                try {
                    const res = await this.$api.unit.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getAssets() {
                try {
                    const res = await this.$api.asset.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getAssetName(id) {
                if ( this.assetList.length !== 0 && id ) {
                    return this.assetList.find(asset => asset.id === id).name;
                }
            },
        },
        mounted(){
            this.list();
            this.getAdditionalData();
        }
    }
</script>