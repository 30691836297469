<template>
    <v-container fluid>
		<div v-if="authed == true" >
			<v-row dense>
				<v-col
					v-for="card in dashboardElement"
					:key="card.title"
					:cols="12"
					:sm="card.flex"
				>
				<v-card
					:color="card.color"
					dark
					class="text-left"
				>
					<v-list-item two-line>
					<v-list-item-content>
						<v-list-item-title class="headline">
						{{card.title}}
						</v-list-item-title>
					</v-list-item-content>
					</v-list-item>
					<v-card-text>
					<v-row align="center">
						<v-col
						class="display-3"
						cols="6"
						>
						{{card.data}}
						</v-col>
					</v-row>
					</v-card-text>
					<!-- <v-card-actions>
					<v-btn text>
						Full Report
					</v-btn>
					</v-card-actions> -->
				</v-card>
				</v-col>
			</v-row>
			<!-- This is the dashboard/homepage after login. <br/>
			You are now in {{mode}} mode. -->
		</div>
	</v-container>
</template>

<script>
	export default {
		components: {
		},
		data() {
			return {
				mode: process.env.VUE_APP_MODE,
				dashboardElement: {
					checklistDue: { title: 'Checklist Due', color: '#385F73', flex: 4, data: null },
					checklistChecked: { title: 'Checklist Checked', color: '#952175', flex: 4, data: null },
					checklistVerified: { title: 'Checklist Verified', color: '#1F7087', flex: 4, data: null },
				},
			}
		},
		props: {
			source: String,
		},
		methods: {
			async getStat() {
                try {
                    this.loading = true
					const res = await this.$api.statistics.today();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
			}
		},
		computed: {
			authed: function () {
				return this.$store.getters.isLoggedIn
			}
		},
		mounted() {
			this.getStat().then((res) => {
				// console.log(res);
				this.dashboardElement.checklistDue.data = res.due;
				this.dashboardElement.checklistChecked.data = res.checked;
				this.dashboardElement.checklistVerified.data = res.verified;
			});
		}
	}
</script>