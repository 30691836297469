import Vue from 'vue'
import Router from 'vue-router'
import store from '@/services/store'
import home from '@/pages/home'
import login from '@/pages/login'
import verifyUser from '@/pages/account/verify-user'
import register from '@/pages/account/register'
import checklistList from '@/pages/checklist-list'
import checklistForm from '@/pages/checklist-form'
import masterComponentList from '@/pages/master-component-list'
import masterComponentForm from '@/pages/master-component-form'
import baseAssetList from '@/pages/base-asset-list'
import baseAssetForm from '@/pages/base-asset-form'
import baseUnitList from '@/pages/base-unit-list'
import baseUnitForm from '@/pages/base-unit-form'
import assetList from '@/pages/asset-list'
import assetForm from '@/pages/asset-form'
import componentList from '@/pages/component-list'
import componentForm from '@/pages/component-form'
import levelList from '@/pages/level-list'
import levelForm from '@/pages/level-form'
import systemList from '@/pages/system-list'
import systemForm from '@/pages/system-form'
import teamList from '@/pages/team-list'
import teamForm from '@/pages/team-form'
import unitList from '@/pages/unit-list'
import unitForm from '@/pages/unit-form'
import masterParameterList from '@/pages/master-parameter-list'
import masterParameterForm from '@/pages/master-parameter-form'
import userPreference from '@/pages/user-preference'
import globalPreference from '@/pages/global-preference'
import userList from '@/pages/user-list'
import userForm from '@/pages/user-form'
import assetPlanForm from '@/pages/asset-plan-form'
import recurrenceList from '@/pages/recurrence-list'
import recurrenceForm from '@/pages/recurrence-form'
import checkplanList from '@/pages/checkplan-list'
import checkplanForm from '@/pages/checkplan-form'

import checkVerifyList from '@/pages/checking/check-verify-list'
import checkVerifyForm from '@/pages/checking/check-verify-form'
import profileForm from '@/pages/account/profile'


Vue.use(Router)

const VueRouter = new Router({
  routes: [
    {
      path: '/',
      redirect:'index.html',
      name: 'home',
      components: home,
      meta: {
        title: 'Home',
        layout: 'crud'
      }
    },
    {
      path: '/index.html',
      name: 'home',
      component: home,
      meta: {
        title: 'Home',
        layout: 'crud'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: login,
      meta: {
        title: 'Login',
        layout: 'empty'
      }
    },
    {
      path: '/systems',
      name: 'systemList',
      component: systemList,
      meta: {
        title: 'System',
        layout: 'crud'
      }
    },
    {
      path: '/system/:id?',
      name: 'systemForm',
      props: castRouteParams,
      component: systemForm,
      meta: {
        title: 'System',
        layout: 'crud'
      }
    },
    {
      path: '/master-components',
      name: 'masterComponentList',
      component: masterComponentList,
      meta: {
        title: 'Master Component',
        layout: 'crud'
      }
    },
    {
      path: '/master-component/:id?',
      name: 'masterComponentForm',
      props: castRouteParams,
      component: masterComponentForm,
      meta: {
        title: 'Master Component',
        layout: 'crud'
      }
    },
    {
      path: '/base-assets',
      name: 'baseAssetList',
      component: baseAssetList,
      meta: {
        title: 'Base Asset',
        layout: 'crud'
      }
    },
    {
      path: '/base-asset/:id?',
      name: 'baseAssetForm',
      props: castRouteParams,
      component: baseAssetForm,
      meta: {
        title: 'Base Asset',
        layout: 'crud'
      }
    },
    {
      path: '/base-units',
      name: 'baseUnitList',
      component: baseUnitList,
      meta: {
        title: 'Base Unit',
        layout: 'crud'
      }
    },
    {
      path: '/base-unit/:id?',
      name: 'baseUnitForm',
      props: castRouteParams,
      component: baseUnitForm,
      meta: {
        title: 'Base Unit',
        layout: 'crud'
      }
    },
    {
      path: '/assets',
      name: 'assetList',
      component: assetList,
      meta: {
        title: 'Asset',
        layout: 'crud'
      }
    },
    {
      path: '/asset/:id?',
      name: 'assetForm',
      props: castRouteParams,
      component: assetForm,
      meta: {
        title: 'Asset',
        layout: 'crud'
      }
    },
    {
      path: '/components',
      name: 'componentList',
      component: componentList,
      meta: {
        title: 'Component',
        layout: 'crud'
      }
    },
    {
      path: '/component/:id?',
      name: 'componentForm',
      props: castRouteParams,
      component: componentForm,
      meta: {
        title: 'Component',
        layout: 'crud'
      }
    },
    {
      path: '/levels/:lvl',
      name: 'levelList',
      props: true,
      component: levelList,
      params: {
        lvl: ':lvl'
      },
      meta: {
        title: 'Level',
        layout: 'crud'
      }
    },
    {
      path: '/level/:lvl/:id?',
      name: 'levelForm',
      props: castRouteParams,
      component: levelForm,
      params: {
        lvl: ':lvl'
      },
      meta: {
        title: 'Level',
        layout: 'crud'
      }
    },
    {
      path: '/teams',
      name: 'teamList',
      component: teamList,
      meta: {
        title: 'Team',
        layout: 'crud'
      }
    },
    {
      path: '/team/:id?',
      name: 'teamForm',
      props: castRouteParams,
      component: teamForm,
      meta: {
        title: 'Team',
        layout: 'crud'
      }
    },
    {
      path: '/units',
      name: 'unitList',
      component: unitList,
      meta: {
        title: 'Unit',
        layout: 'crud'
      }
    },
    {
      path: '/unit/:id?',
      name: 'unitForm',
      component: unitForm,
      props: castRouteParams,
      meta: {
        title: 'Unit',
        layout: 'crud'
      }
    },
    {
      path: '/master-parameters',
      name: 'masterParameterList',
      component: masterParameterList,
      meta: {
        title: 'Master Parameter',
        layout: 'crud'
      }
    },
    {
      path: '/master-parameter/:id?',
      name: 'masterParameterForm',
      props: true,
      component: masterParameterForm,
      meta: {
        title: 'Master Parameter',
        layout: 'crud'
      }
    },
    {
      path: '/user-preference',
      name: 'user-preference',
      component: userPreference,
      meta: {
        title: 'User Preference',
        layout: 'crud'
      }
    },
    {
      path: '/global-preference',
      name: 'global-preference',
      component: globalPreference,
      meta: {
        title: 'Global Preference',
        layout: 'crud'
      }
    },
    {
      path: '/users',
      name: 'userList',
      component: userList,
      meta: {
        title: 'User',
        layout: 'crud'
      }
    },
    {
      path: '/user/:id?',
      name: 'userForm',
      component: userForm,
      props: castRouteParams,
      meta: {
        title: 'User',
        layout: 'crud'
      }
    },
    {
      path: '/checklists',
      name: 'checklistList',
      component: checklistList,
      meta: {
        title: 'Checklist',
        layout: 'crud'
      }
    },
    {
      path: '/checklist/:id?',
      name: 'checklistForm',
      props: castRouteParams,
      component: checklistForm,
      meta: {
        title: 'CheckList',
        layout: 'crud'
      }
    },
    {
      path: '/asset-plan/:id?',
      name: 'assetPlanForm',
      props: castRouteParams,
      component: assetPlanForm,
      meta: {
        title: 'Asset Plan',
        layout: 'crud'
      }
    },
    {
      path: '/recurrences',
      name: 'recurrenceList',
      component: recurrenceList,
      meta: {
        title: 'Recurrence',
        layout: 'crud'
      }
    },
    {
      path: '/recurrence/:id?',
      name: 'recurrenceForm',
      props: castRouteParams,
      component: recurrenceForm,
      meta: {
        title: 'Recurrence',
        layout: 'crud'
      }
    },
    {
      path: '/checkplans',
      name: 'checkplanList',
      component: checkplanList,
      meta: {
        title: 'Checkplan',
        layout: 'crud'
      }
    },
    {
      path: '/checkplan/:id?',
      name: 'checkplanForm',
      props: castRouteParams,
      component: checkplanForm,
      meta: {
        title: 'Checkplan',
        layout: 'crud'
      }
    },
    {
      path: '/checklist-checks',
      name: 'checkVerifyList',
      component: checkVerifyList,
      meta: {
        title: 'My Checklist',
        layout: 'simple'
      }
    },
    {
      path: '/checklist-check/:id?',
      name: 'checkVerifyForm',
      props: castRouteParams,
      component: checkVerifyForm,
      meta: {
        title: 'My Checklist',
        layout: 'simple'
      }
    },
    {
      path: '/profile/:id?',
      name: 'profileForm',
      props: castRouteParams,
      component: profileForm,
      meta: {
        title: 'My Profile',
        layout: 'crud'
      }
    },
    {
      path: '/register',
      name: 'register',
      component: register,
      meta: {
        title: 'Register',
        layout: 'empty'
      }
    },
    {
      path: '/verify-user',
      name: 'verifyUser',
      component: verifyUser,
      meta: {
        title: 'Verify User',
        layout: 'empty'
      }
    },
    
  ],
  duplicateNavigationPolicy: 'reload',
  mode: 'history'
})
export default VueRouter

function castRouteParams(route) {
  return {
    id: Number(route.params.id),
    checklistId: Number(route.params.checklistId),
    checkplanId: Number(route.params.checkplanId),
    recurranceTimeList: Number(route.params.recurranceTimeList),
    assetPlanList: Number(route.params.assetPlanList),
  };
}
VueRouter.beforeEach((to, from, next) => {
  store.dispatch('CANCEL_PENDING_REQUESTS');
  next();
})