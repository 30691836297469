<template>
    <v-container fluid class="list-system">
      <v-row>
        <v-col cols="12">
            <div class="py-2 px-4 text-left command-button-list">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon>mdi-arrow-left</v-icon>
                    Back
                </v-btn>
            </div>
            <v-card class="pa-3 mb-3 mx-auto text-right">
                <v-form lazy-validation
                    ref="form"
                    v-model="valid">
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                label="Name" 
                                v-model="input.name"
                                :rules="nameRules"
                                :counter="50"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-menu
                                v-model="fromDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y 
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                <v-text-field
                                    label="From"
                                    readonly
                                    :value="fromDateDisp"
                                    v-on="on"

                                ></v-text-field>
                                </template>
                                <v-date-picker
                                locale="en-in"
                                v-model="input.start"
                                no-title
                                @input="fromDateMenu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-menu
                                v-model="ToDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y 
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                <v-text-field
                                    label="To"
                                    readonly
                                    :value="ToDateDisp"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                locale="en-in"
                                v-model="input.until"
                                no-title
                                @input="ToDateMenu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-textarea
                                outlined
                                label="Description" 
                                v-model="input.description"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                            :items="optionFrequency" 
                            item-value="value"
                            name="item"
                            item-text="name"
                            label="Frequency" 
                            v-model="input.frequency"
                            ></v-select>
                        </v-col>
                        <v-col>
                            <v-select
                            :items="optionInterval" 
                            item-value="value"
                            name="item"
                            item-text="name"
                            label="Interval" 
                            v-model="input.interval"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                            v-if="input.frequency == 'WEEKLY_NUMBER' || input.frequency == 'WEEKLY_INTERVAL'"
                            :items="optionDayInAWeek" 
                            item-value="value"
                            name="item"
                            item-text="name"
                            label="Day in a Week" 
                            multiple
                            chips
                            v-model="input.day_in_week"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                            v-if="input.frequency == 'WEEKLY_NUMBER'"
                            :items="optionWeekNumber" 
                            item-value="value"
                            name="item"
                            item-text="name"
                            label="Day in a Week" 
                            multiple
                            chips
                            v-model="input.week_number"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                            v-if="input.frequency == 'MONTHLY_DAY'"
                            :items="optionDayInAMonth" 
                            item-value="value"
                            name="item"
                            item-text="name"
                            label="Day in a Month"
                            multiple
                            chips 
                            v-model="input.day_in_month"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                            v-if="input.frequency == 'MONTHLY_DATE'"
                            :items="optionDateInAMonth" 
                            item-value="value"
                            name="item"
                            item-text="name"
                            label="Date in a Month" 
                            multiple
                            chips
                            v-model="input.date_in_month"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="text-right">
                            <v-btn class="mr-4" color="primary" :disabled="!valid" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
	export default {
          props: ['id'],
		data: () => ({
            success: false,
            input: {
                id:'',
                name: '',
                description: '',
                frequency: '',
                interval: '',
                start: '',
                until: '',
                day_in_week: [],
                day_in_month: [],
                date_in_month: [],
                week_number: [],
            },
            loading: true,
            fromDateMenu: false,
            ToDateMenu: false,
            fromDateVal: '',
            ToDateVal: '',
            optionFrequency:[
                {
                value: "DAILY",
                name: "daily"
                },
                {
                value: "WEEKLY_INTERVAL",
                name: "weekly (interval)"
                },
                {
                value: "WEEKLY_NUMBER",
                name: "weekly (number)"
                },
                {
                value: "MONTHLY_DAY",
                name: "monthly (day)"
                },
                {
                value: "MONTHLY_DATE",
                name: "monthly (date)"
                },
            ],
            optionInterval:[
                {
                value: 1,
                name: "One"
                },
                {
                value: 2,
                name: "Two"
                },
                {
                value: 3,
                name: "Three"
                },
                {
                value: 4,
                name: "Four"
                },
                {
                value: 5,
                name: "Five"
                },
                {
                value: 6,
                name: "Six"
                },
            ],
            optionDayInAWeek:[
                {
                value: "MON",
                name: "Monday"
                },
                {
                value: "TUE",
                name: "Tuesday"
                },
                {
                value: "WED",
                name: "Wednesday"
                },
                {
                value: "THU",
                name: "Thursday"
                },
                {
                value: "FRI",
                name: "Friday"
                },
                {
                value: "SAT",
                name: "Saturday"
                },
                {
                value: "SUN",
                name: "Sunday"
                },
            ], 
            optionDayInAMonth:[
                {
                    value: "MON",
                    name: "every mon"
                },
                {
                    value: "TUE",
                    name: "every tue"
                },
                {
                    value: "WED",
                    name: "every wed"
                },
                {
                    value: "THU",
                    name: "every thu"
                },
                {
                    value: "FRI",
                    name: "every fri"
                },
                {
                    value: "SAT",
                    name: "every sat"
                },
                {
                    value: "SUN",
                    name: "every sun"
                },
                {
                    value: "MON+1",
                    name: "first mon"
                },
                {
                    value: "TUE+1",
                    name: "first tue"
                },
                {
                    value: "WED+1",
                    name: "first wed"
                },
                {
                    value: "THU+1",
                    name: "first thu"
                },
                {
                    value: "FRI+1",
                    name: "first fri"
                },
                {
                    value: "SAT+1",
                    name: "first sat"
                },
                {
                    value: "SUN+1",
                    name: "first sun"
                },
                {
                    value: "MON-1",
                    name: "last mon"
                },
                {
                    value: "TUE-1",
                    name: "last tue"
                },
                {
                    value: "WED-1",
                    name: "last wed"
                },
                {
                    value: "THU-1",
                    name: "last thu"
                },
                {
                    value: "FRI-1",
                    name: "last fri"
                },
                {
                    value: "SAT-1",
                    name: "last sat"
                },
                {
                    value: "SUN-1",
                    name: "last sun"
                }
            ],
            optionDateInAMonth: [
                {
                    value: 1,
                    name: "1"
                },
                {
                    value: 2,
                    name: "2"
                },
                {
                    value: 3,
                    name: "3"
                },
                {
                    value: 4,
                    name: "4"
                },
                {
                    value: 5,
                    name: "5"
                },
                {
                    value: 6,
                    name: "6"
                },
                {
                    value: 7,
                    name: "7"
                },
                {
                    value: 8,
                    name: "8"
                },
                {
                    value: 9,
                    name: "9"
                },
                {
                    value: 10,
                    name: "10"
                },
                {
                    value: 11,
                    name: "11"
                },
                {
                    value: 12,
                    name: "12"
                },
                {
                    value: 13,
                    name: "13"
                },
                {
                    value: 14,
                    name: "14"
                },
                {
                    value: 15,
                    name: "15"
                },
                {
                    value: 16,
                    name: "16"
                },
                {
                    value: 17,
                    name: "17"
                },
                {
                    value: 18,
                    name: "18"
                },
                {
                    value: 19,
                    name: "19"
                },
                {
                    value: 20,
                    name: "20"
                },
                {
                    value: 21,
                    name: "21"
                },
                {
                    value: 22,
                    name: "22"
                },
                {
                    value: 23,
                    name: "23"
                },
                {
                    value: 24,
                    name: "24"
                },
                {
                    value: 25,
                    name: "25"
                },
                {
                    value: 26,
                    name: "26"
                },
                {
                    value: 27,
                    name: "27"
                },
                {
                    value: 28,
                    name: "28"
                },
                {
                    value: 29,
                    name: "29"
                },
                {
                    value: 30,
                    name: "30"
                },
                {
                    value: 31,
                    name: "31"
                }
            ],
            optionWeekNumber:[
                {
                    value: 1,
                    name: "W01"
                },
                {
                    value: 2,
                    name: "W02"
                },
                {
                    value: 3,
                    name: "W03"
                },
                {
                    value: 4,
                    name: "W04"
                },
                {
                    value: 5,
                    name: "W05"
                },
                {
                    value: 6,
                    name: "W06"
                },
                {
                    value: 7,
                    name: "W07"
                },
                {
                    value: 8,
                    name: "W08"
                },
                {
                    value: 9,
                    name: "W09"
                },
                {
                    value: 10,
                    name: "W10"
                },
                {
                    value: 11,
                    name: "W11"
                },
                {
                    value: 12,
                    name: "W12"
                },
                {
                    value: 13,
                    name: "W13"
                },
                {
                    value: 14,
                    name: "W14"
                },
                {
                    value: 15,
                    name: "W15"
                },
                {
                    value: 16,
                    name: "W16"
                },
                {
                    value: 17,
                    name: "W17"
                },
                {
                    value: 18,
                    name: "W18"
                },
                {
                    value: 19,
                    name: "W19"
                },
                {
                    value: 20,
                    name: "W20"
                },
                {
                    value: 21,
                    name: "W21"
                },
                {
                    value: 22,
                    name: "W22"
                },
                {
                    value: 23,
                    name: "W23"
                },
                {
                    value: 24,
                    name: "W24"
                },
                {
                    value: 25,
                    name: "W25"
                },
                {
                    value: 26,
                    name: "W26"
                },
                {
                    value: 27,
                    name: "W27"
                },
                {
                    value: 28,
                    name: "W28"
                },
                {
                    value: 29,
                    name: "W29"
                },
                {
                    value: 30,
                    name: "W30"
                },
                {
                    value: 31,
                    name: "W31"
                },
                {
                    value: 32,
                    name: "W32"
                },
                {
                    value: 33,
                    name: "W33"
                },
                {
                    value: 34,
                    name: "W34"
                },
                {
                    value: 35,
                    name: "W35"
                },
                {
                    value: 36,
                    name: "W36"
                },
                {
                    value: 37,
                    name: "W37"
                },
                {
                    value: 38,
                    name: "W38"
                },
                {
                    value: 39,
                    name: "W39"
                },
                {
                    value: 40,
                    name: "W40"
                },
                {
                    value: 41,
                    name: "W41"
                },
                {
                    value: 42,
                    name: "W42"
                },
                {
                    value: 43,
                    name: "W43"
                },
                {
                    value: 44,
                    name: "W44"
                },
                {
                    value: 45,
                    name: "W45"
                },
                {
                    value: 46,
                    name: "W46"
                },
                {
                    value: 47,
                    name: "W47"
                },
                {
                    value: 48,
                    name: "W48"
                },
                {
                    value: 49,
                    name: "W49"
                },
                {
                    value: 50,
                    name: "W50"
                },
                {
                    value: 51,
                    name: "W51"
                },
                {
                    value: 52,
                    name: "W52"
                },
                {
                    value: 53,
                    name: "W53"
                }
            ],
            valid: true,
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 50) || 'Name must be less than 50 characters',
            ],
        }),
        computed: {
            fromDateDisp() {
            return this.input.start;
            // format/do something with date
            },
            ToDateDisp() {
                return this.input.until;
            }
        },  
		methods: {
            // Main Function
			form() {
                if (this.id) {
                    this.getRecurrence().then((res) => {
                        this.input.id = res.id;
                        this.input.name = res.name;
                        this.input.description = res.description;
                        this.input.frequency = res.frequency;
                        this.input.interval = res.interval;
                        this.input.start = res.start;
                        this.input.until = res.until;
                        this.input.day_in_week = res.day_in_week;
                        this.input.day_in_month = res.day_in_month;
                        this.input.date_in_month = res.date_in_month;
                        this.input.week_number = res.week_number
                    });
                }
            },
			async getRecurrence() {
                try {
                    this.loading = true
                    const res = await this.$api.recurrence.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			save() {
                this.saveRecurrence().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'recurrenceList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                })
			},
			async saveRecurrence() {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.recurrence.save( this.input );
                    } else {
                        res = await this.$api.recurrence.new( this.input );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			quitEdit() {
                this.$router.push({ name: 'recurrenceList' })
            },
		},
		mounted () {
			this.form();
		},
	}
</script>