<template>
  <div>
      <Header v-if="authed" :drawerStatus="drawer" @update="drawer = $event;" :globalPref="globalPref"></Header>
        <v-navigation-drawer v-if="authed" 
			v-model="drawer"
			app
			color="grey darken-4"
			dark
			>
				<div class="mt-8 mb-5">
					<span class="white--text">{{companyName}}</span>
				</div>
				<Menu :globalPref="globalPref"></Menu>
        </v-navigation-drawer>
        <v-content v-if="authed">
            <v-container fluid>
                <v-row>
                    <v-col cols="12">
                        <slot />
                    </v-col>
                </v-row>
            </v-container>
        </v-content>
  </div>
</template>
<script>
import login from '@/pages/login';
import Header from '@/components/common/header.vue';
import Menu from '@/components/common/menu.vue';
export default {
    components: {
        Header,
        Menu,
        login,
    },
    data: () => ({
        drawer: false,
        windowWidth: 0,
    }),
    props:{
        globalPref: Array
    },
    computed: {
        companyName: function() {
            if (this.authed && this.globalPref.length > 0) {
                return (this.globalPref.find(pref => pref.name === 'organization_name').value);
            }
        },
        authed: function() {
            return this.$store.getters.isLoggedIn
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        checkLogin() {
            if (!this.authed) {
			    this.$router.push('/login');
            }
        }
    },
    watch: {
        windowWidth(width) {
            if (width >= 1264) {
                this.drawer = true;
            } else {
                this.drawer = false;
            }
        }
    },
    mounted: function () {
        this.checkLogin();
    }
};
</script>
