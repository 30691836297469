<template>
    <v-container fluid class="list-system">
      <v-row>
        <v-col cols="12">
            <div class="py-2 px-4 text-left command-button-list">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon>mdi-arrow-left</v-icon>
                    Back
                </v-btn>
            </div>
        <v-card class="pa-3 mb-3 mx-auto">
        <v-form lazy-validation
            ref="form"
            v-model="valid">
            <v-container fluid >
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-text-field
                            :label="input.id ? 'Name' : 'Name will be generated automatically'" 
                            v-model="input.name"
                            :rules="nameRules"
                            :counter="50"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-left" cols="12" sm="6" md="6">
                        <!-- <div class="caption">Condition</div>
                        <v-btn-toggle v-model="input.condition">
                            <v-btn value="misc">Misc</v-btn>
                            <v-btn value="bad">Bad</v-btn>
                            <v-btn value="partial">Partial</v-btn>
                            <v-btn value="good">Good</v-btn>
                        </v-btn-toggle> -->
                        <div class="caption">Status</div>
                         <v-switch inset v-model="input.is_active" label="Status"></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-select
                        :readonly="!editMode"
                        :items="systemList" 
                        item-value="id"
                        name="item"
                        item-text="name"
                        label="System" 
                        v-model="input.base_asset_system"
                        @change="getBaseAssets()"
                        ></v-select>
                        <v-autocomplete ref="internal" 
                            :readonly="!editMode"
                            v-model="input.base_asset"
                            no-filter
                            hide-no-data
                            :items="baseAssetList" 
                            name="item"
                            item-value="id"
                            label="Base Asset" 
                            item-text="name"
                            @change="getUnitNamebyBaseAsset()"
                            >
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <!-- <v-col>
                        <v-text-field
                            label="Capacity Unit" 
                            v-model="input.capacity_unit"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                            label="Consumption Unit" 
                            v-model="input.consumption_unit"
                        ></v-text-field>
                    </v-col> -->
                    <v-col cols="12" sm="6" md="4">
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="input.purchase_date"
                                label="Purchase Date"
                                prepend-icon="mdi-calendar-check"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker v-model="input.purchase_date" no-title>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        
                        <v-menu
                            ref="menuWarranty"
                            v-model="menuWarranty"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="input.warranty_date"
                                label="Warranty Date"
                                prepend-icon="mdi-calendar-check"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker v-model="input.warranty_date" no-title>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            label="Purchase Cost" 
                            v-model="input.purchase_cost"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            label="Manufacturer" 
                            v-model="input.manufacturer"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            label="Manufacturer Tag" 
                            v-model="input.manufacturer_tag"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            label="Manual Tag" 
                            v-model="input.manual_tag"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            label="Model" 
                            v-model="input.model"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            label="Person in charge" 
                            v-model="input.person_in_charge"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            :label="'Capacity - '+ info.capacity_unit" 
                            v-model="input.capacity"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            :label="'Consumption - '+ info.consumption_unit" 
                            v-model="input.consumption"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-divider light class="mt-3 mb-3"></v-divider>
                <div class="subtitle text-left mb-3">Levels for this Asset</div>
                <v-row>
                    <v-col cols="12" sm="6" md="3">
                        <v-autocomplete ref="internal" 
                            :readonly="!editMode"
                            v-model="input.level_one"
                            cache-items
                            hide-no-data
                            :items="level1List" 
                            :label="level1Label" 
                            name="item"
                            item-value="id"
                            @change="getLevelSelect(2, input.level_one)"
                            :rules="locationRules"
                            item-text="name">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-autocomplete ref="internal" 
                            :readonly="!editMode"
                            v-if="this.maxLevel >=2 "
                            v-model="input.level_two"
                            cache-items
                            hide-no-data
                            :items="level2List" 
                            name="item"
                            item-value="id"
                            :label="level2Label" 
                            item-text="name">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-select
                        :readonly="!editMode"
                        v-if="this.maxLevel >=3 "
                        :items="level3List" 
                        item-value="id"
                        name="item"
                        item-text="name"
                        :label="level3Label" 
                        v-model="input.level_three"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="3">
                        <v-select
                        :readonly="!editMode"
                        v-if="this.maxLevel >=4 "
                        :items="level4List" 
                        item-value="id"
                        name="item"
                        item-text="name"
                        :label="level4Label" 
                        v-model="input.level_four"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-select
                        :readonly="!editMode"
                        v-if="this.maxLevel >=5 "
                        :items="level5List" 
                        item-value="id"
                        name="item"
                        item-text="name"
                        :label="level5Label" 
                        v-model="input.level_five"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row class="text-right">
                    <v-col cols="12">
                        <v-btn class="mr-4" v-if="!editMode && id" @click="editMode = true"><v-icon class="mr-2">mdi-pencil</v-icon> Edit</v-btn>
                        <v-btn class="mr-4" v-if="editMode" @click="editMode = false"><v-icon class="mr-2">mdi-close-box-multiple</v-icon> Cancel Edit</v-btn>
                        <v-btn class="mr-4" v-if="editMode || !id" :disabled="!valid" color="primary" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                    </v-col>
                </v-row><v-divider light></v-divider>
                <v-row v-if="paramsbyAsset.length > 0">
                    <v-col cols="12">
                        <div class="subtitle text-left mb-3">Parameters in this Asset</div>
                        <v-data-table 
                        :loading="this.loadingParams" loading-text="Loading... Please wait"
                        :headers="headersParams"
                        :sort-by="['id']"
                        :sort-desc="[true]"
                        :items="this.paramsbyAsset"
                        :items-per-page="20"
                        :footer-props="{
                            itemsPerPageOptions: [20,40,60],
                        }" 
                        class="full-width">
                        <template v-slot:item="row">
                            <tr>
                            <td class="text-left" v-if="row.item.id">{{ getParamName(row.item.base_parameter)}}</td>
                            <td class="text-left">
                                {{row.item.baseParam_auto_mode}}
                            </td>
                            <td class="text-left">
                                {{row.item.baseParam_condition_mode}}
                            </td>
                            <td class="text-left">
                                <v-text-field
                                    label="Lower Limit"
                                    v-model="row.item.lower_limit" 
                                    :placeholder="'(Base: '+ row.item.baseParam_lower_limit + ')'"
                                ></v-text-field>
                            </td>
                            <td class="text-left">
                                <v-text-field
                                    label="Upper Limit"
                                    v-model="row.item.upper_limit" 
                                    :placeholder="'(Base: '+ row.item.baseParam_upper_limit + ')'"
                                ></v-text-field>
                            </td>
                            <td class="text-left">
                                <v-select
                                :items="conditionOptions" 
                                item-value="value"
                                name="item"
                                item-text="name"
                                label="Condition" 
                                v-model="row.item.condition"
                                ></v-select>
                            </td>
                            <td>
                                <v-btn class="mr-4" @click="saveParam(row.item.id, row.item.lower_limit, row.item.upper_limit, row.item.condition)"><v-icon class="mr-2">mdi-content-save</v-icon> Save</v-btn>
                                <!-- <v-btn text class="mr-4" @click="delComponent(index, row.item.id, row.item.name, row.item.base_component)"><v-icon>mdi-delete</v-icon></v-btn> -->
                            </td>
                            </tr>
                        </template>
                    </v-data-table>
                    </v-col>
                </v-row>
                <v-divider light></v-divider>
                <v-row v-if="compsbyAsset.length > 0">
                    <v-col cols="12">
                        <div class="subtitle text-left mb-3">Components in this Asset</div>
                        <v-data-table 
                        :loading="this.loadingComps" loading-text="Loading... Please wait"
                        :headers="headersComps"
                        :sort-by="['id']"
                        :sort-desc="[true]"
                        :items="this.compsbyAsset"
                        :items-per-page="20"
                        :footer-props="{
                            itemsPerPageOptions: [20,40,60],
                        }" 
                        class="full-width">
                        <template v-slot:item="row">
                            <tr>
                            <td class="text-left">{{ getBaseCompName(row.item.base_component)}}</td>
                            <td class="text-left">
                                {{row.item.name}}
                            </td>
                            <td class="text-left">
                                <v-btn-toggle v-model="row.item.condition">
                                    <v-btn value="misc">Misc</v-btn>
                                    <v-btn value="bad">Bad</v-btn>
                                    <v-btn value="partial">Partial</v-btn>
                                    <v-btn value="good">Good</v-btn>
                                </v-btn-toggle>
                            </td>
                            <td>
                                <v-btn class="mr-4" @click="saveComp(row.item.id, row.item.name, row.item.base_component, row.item.condition)"><v-icon class="mr-2">mdi-content-save</v-icon> Save</v-btn>
                                <!-- <v-btn text class="mr-4" @click="delComponent(index, row.item.id, row.item.name, row.item.base_component)"><v-icon>mdi-delete</v-icon></v-btn> -->
                            </td>
                            </tr>
                        </template>
                    </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
        </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
	export default {
		data: () => ({
            headersParams: [
            { text: 'Name', align: 'left', sortable: true, value: 'name', },
            { text: 'Automatic Mode', value: 'auto_mode' },
            { text: 'Condition Mode', value: 'condition_mode' },
            { text: 'Lower Limit', value: 'lower_limit' },
            { text: 'Upper Limit', value: 'upper_limit' },
            { text: 'Condition', value: 'condition' },
            { text: ''}
            ],
            headersComps: [
            { text: 'Base Component', align: 'left', sortable: true, value: 'id', },
            { text: 'Component', value: 'name' },
            { text: 'Condition', value: 'condition' },
            { text: ''}
            ],
            conditionOptions: [
                { name: 'unknown', value:'UNKNOWN' }, 
                { name: 'good', value:'GOOD' }, 
                { name: 'bad', value:'BAD' }, 
                { name: 'other', value:'OTHER' }
            ],
            systemList:[],
            loading: true,
            editMode: false,
            input: {
                id: '',
                name: '',
                base_asset_system: '',
                condition: '',
                base_asset: '',
                level_one: '',
                level_two: '',
                level_three: '',
                level_four: '',
                level_five: '',
                is_active: false,
                purchase_cost: 0,
                purchase_date: null,
                manufacturer: '',
                manufacturer_tag: '',
                manual_tag: '',
                model: '',
                warranty_date: null,
                person_in_charge: '',
                capacity: 0,
                consumption: 0,
            },
            info: {
                capacity_unit: '',
                consumption_unit: '',
            },
            menu: false,
            menuWarranty: false,
            baseAssetList: [],
            baseCompList: [],
            compsbyAsset: [],
            level1List: [],
            level2List: [],
            level3List: [],
            level4List: [],
            level5List: [],
            loadingComps: true,
            paramsbyAsset: [],
            loadingParams:true,
            baseParamList: [],
            masterParamList:[] ,
            valid: true,
            nameRules: [
                v => (v.length <= 50) || 'Name must be less than 50 characters',
            ],
            locationRules: [
                v => !!v || 'Location is required',
            ],
        }),
        props: {
            globalPref: {
                type: Array,
                required: false
            },
            id: Number   
        },
        computed: {
			maxLevel: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'maximum_level').value);
				} else {
                    return 5;
                }
			},
			level1Label: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'level_one_label').value);
				} else {
                    return 'Level 1';
                }
			},
			level2Label: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'level_two_label').value);
				} else {
                    return 'Level 2';
                }
			},
			level3Label: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'level_three_label').value);
				} else {
                    return 'Level 3';
                }
			},
			level4Label: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'level_four_label').value);
				} else {
                    return 'Level 4';
                }
			},
			level5Label: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'level_five_label').value);
				} else {
                    return 'Level 5';
                }
            },
        },
		methods: {
			quitEdit() {
                this.$router.push({ name: 'assetList' })
            },
            form() {
                if (this.id) {
                    this.getAsset().then((res) => {
                    this.input.id = res.id;
                    this.input.name = res.name;
                    this.input.base_asset = res.base_asset;
                    this.input.condition = res.condition;
                    this.input.level_one = res.level_one;
                    this.input.level_two = res.level_two;
                    this.input.level_three = res.level_three;
                    this.input.level_four = res.level_four;
                    this.input.level_five =  res.level_five;
                    this.input.is_active = res.is_active;
                    this.input.purchase_cost = res.purchase_cost;
                    this.input.purchase_date = res.purchase_date;
                    this.input.manufacturer = res.manufacturer;
                    this.input.manufacturer_tag = res.manufacturer_tag;
                    this.input.manual_tag = res.manual_tag;
                    this.input.model = res.model;
                    this.input.warranty_date = res.warranty_date;
                    this.input.person_in_charge = res.person_in_charge;
                    this.input.capacity = res.capacity;
                    this.input.consumption = res.consumption;
                    this.input.base_asset_system = res.base_asset_system;
                    this.getUnitNamebyBaseAsset();
                    if (res.level_one) {
                        this.getLevelSelect(2, res.level_one)
                    }
                    if (res.level_two) {
                        this.getLevelSelect(3, res.level_two)
                    }
                    if (res.level_three) {
                        this.getLevelSelect(4, res.level_three)
                    }
                    if (res.level_four) {
                        this.getLevelSelect(5, res.level_four)
                    }
                    });
                }
            },
			async getAsset() {
                try {
                    this.loading = true
                    const res = await this.$api.asset.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            save() {
                this.saveAsset().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'assetList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                });
			},
			async saveAsset() {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.asset.save( this.input );
                    } else {
                        res = await this.$api.asset.new( this.input );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            // Additional Infos
            getAdditionalData() {
			    this.getSystems().then(res => this.systemList = res);
                this.getBaseAssets();
                this.getBaseComps().then(res => this.baseCompList = res);
                this.getMasterParams().then(res => this.masterParamList = res);
                this.getBaseParameters().then(res => this.baseParamList = res);
                if (this.id) {
                this.getParamList();
                this.getCompList();
                }
            },
			async getSystems() {
                try {
                    const res = await this.$api.system.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getGeneric() {
                this.getGenericbySystem().then((res) => {
                    this.genericList = res;
                });
            },
            getBaseAssets() {
                this.getBaseAssetsbySystem().then(res => this.baseAssetList = res);
            },
			async getBaseAssetsbySystem() {
                try {
                    const res = await this.$api.baseAsset.search({ system: this.input.base_asset_system });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getBaseComps() {
                try {
                    const res = await this.$api.baseComp.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getMasterParams() {
                try {
                    const res = await this.$api.mastParam.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getBaseParameters() {
                try {
                    const res = await this.$api.baseParam.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getCompList() {
                this.getCompsbyAsset().then(res => this.compsbyAsset = res);
            },
			async getCompsbyAsset() {
                try {
                    const res = await this.$api.component.search({ asset: this.id });
                    console.log(res)
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loadingComps = false;
                }
            },
            getParamList() {
                this.getParamsbyAsset().then((res) => {
                    res.forEach((item) => {
                        item.baseParam_auto_mode = this.baseParamList.find((baseParam => baseParam.id === item.base_parameter)).automatic_mode
                        item.baseParam_condition_mode = this.baseParamList.find((baseParam => baseParam.id === item.base_parameter)).condition_mode
                        item.baseParam_lower_limit = this.baseParamList.find((baseParam => baseParam.id === item.base_parameter)).lower_limit;
                        item.baseParam_upper_limit = this.baseParamList.find((baseParam => baseParam.id === item.base_parameter)).upper_limit;
                    })
                    this.paramsbyAsset = res;
                    this.loadingParams = false;
                });
            },
			async getParamsbyAsset() {
                try {
                    const res = await this.$api.parameter.search({ asset: this.id });
                    console.log(res)
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getBaseAssetName(id) {
                if ( this.baseAssetList.length !== 0 && id ) {
                return this.baseAssetList.find(baseAsset => baseAsset.id === id).name;
                }
            },
            getBaseCompName(id) {
                if ( this.baseCompList.length !== 0 && id ) {
                    return this.baseCompList.find(baseComp => baseComp.id === id).name;
                }
            },
            getParamName(param_id) {
                if ( this.baseParamList.length !== 0 && param_id ) {
                    return (this.masterParamList.find(baseParam => baseParam.id === (this.baseParamList.find(baseParam => baseParam.id === param_id).master_parameter)).name)
                }
            },
            saveParam(id, lower_limit, upper_limit, condition) {
                this.saveParameter(id, lower_limit, upper_limit, condition).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            reverseButtons: true,
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.getParamList();
                        })
                    }
                });
			},
			async saveParameter(id, lower_limit, upper_limit, condition) {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.parameter.save( {id: id, lower_limit: lower_limit, upper_limit: upper_limit, condition:condition, asset: this.input.id } );
                    } else {
                        res = await this.$api.parameter.new( {lower_limit: lower_limit, upper_limit: upper_limit, condition:condition, asset: this.input.id } );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            saveComp(id, name, base_component, condition) {
                this.saveComponent(id, name, base_component, condition).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            reverseButtons: true,
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.getParamList();
                        })
                    }
                });
			},
			async saveComponent(id, name, base_component, condition) {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.component.save( {id: id, name: name, base_component: base_component, condition:condition, asset: this.input.id } );
                    } else {
                        res = await this.$api.component.new( { name: name, base_component: base_component, condition:condition, asset: this.input.id } );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            getLevel1List() {
                this.getLevel1(1).then(res => this.level1List = res);
            },
			async getLevel1() {
                try {
                    this.loading = true
                    const res = await this.$api.level.list(1);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            getLevelSelect(level, parent) {
                this.getLevel(level, parent).then((res) => {
                    if (level == '2') {
                        this.level2List = res.filter(level2 => level2.level_one === parent);
                    }
                    if (level == '3') {
                        this.level3List = res.filter(level3 => level3.level_one === parent);
                    }
                    if (level == '4') {
                        this.level4List = res.filter(level4 => level4.level_one === parent);
                    }
                    if (level == '5') {
                        this.level5List = res.filter(level5 => level5.level_one === parent);
                    }
                });
            },
			async getLevel(level, parent) {
                try {
                    this.loading = true
                    const res = await this.$api.level.list(level);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            getUnitNamebyBaseAsset() {
                this.getUnitDatabyBaseAsset().then((res) => {
                    this.info.capacity_unit = res.capacity_unit_data.symbol;
                    this.info.consumption_unit = res.consumption_unit_data.symbol;
                });
            },
			async getUnitDatabyBaseAsset() {
                try {
                    const res = await this.$api.baseAsset.get(this.input.base_asset);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
		},
		mounted () {
            this.form();
            this.getAdditionalData();
            this.getLevel1List();
            this.success = true;
		},
	}
</script>