<template>
    <v-container fluid class="list-level">
      <v-row>
        <v-col cols="12">
            <div class="py-2 px-4 text-left command-button-list">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon>mdi-arrow-left</v-icon>
                    Back
                </v-btn>
            </div>
            <v-card class="pa-3 mb-3 mx-auto">
            <v-form>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" sm="6" md="3">
                            <v-text-field
                                label="Name" 
                                v-model="input.name"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" md="3">
                            <v-select
                            v-if="this.levelSelected == '2'"
                            :items="level1List" 
                            item-value="id"
                            name="item"
                            item-text="name"
                            :label="level1Label" 
                            v-model="input.level_one"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-select
                            v-if="this.levelSelected == '3' && this.maxLevel >=2 "
                            :items="level2List" 
                            item-value="id"
                            name="item"
                            item-text="name"
                            :label="level2Label" 
                            v-model="input.level_two"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-select
                            v-if="this.levelSelected == '4' && this.maxLevel >=3 "
                            :items="level3List" 
                            item-value="id"
                            name="item"
                            item-text="name"
                            :label="level3Label" 
                            v-model="input.level_three"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" md="3">
                            <v-select
                            v-if="this.levelSelected == '5' && this.maxLevel >=4 "
                            :items="level4List" 
                            item-value="id"
                            name="item"
                            item-text="name"
                            :label="level4Label" 
                            v-model="input.level_four"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-select
                            v-if="this.maxLevel >=5 "
                            :items="level5List" 
                            item-value="id"
                            name="item"
                            item-text="name"
                            :label="level5Label" 
                            v-model="input.level_five"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="text-right">
                            <v-btn class="mr-4" color="primary" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
	export default {
        components: {
        },
		data: () => ({
            success: false,
            levelSelected: '',
            level1List: [],
            level2List: [],
            level3List: [],
            level4List: [],
            level5List: [],
            loading: true,
            input: {
                id: '',
                name: '',
                level_one: [],
                level_two: [],
                level_three: [],
                level_four: [],
                level_five: [],
            },
            loading: true,
		}),
        props: {
            globalPref: {
                type: Array,
                required: false
            },
            id: Number   
        },
        computed: {
			maxLevel: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'maximum_level').value);
				} else {
                    return 5;
                }
			},
			level1Label: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'level_one_label').value);
				} else {
                    return 'Level 1';
                }
			},
			level2Label: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'level_two_label').value);
				} else {
                    return 'Level 2';
                }
			},
			level3Label: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'level_three_label').value);
				} else {
                    return 'Level 3';
                }
			},
			level4Label: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'level_four_label').value);
				} else {
                    return 'Level 4';
                }
			},
			level5Label: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'level_five_label').value);
				} else {
                    return 'Level 5';
                }
			}
        },
		methods: {
            // Main Function
            form() {
                if (this.id) {
                    this.getLevel().then((res) => {
                        this.input.id = res.id;
                        this.input.name = res.name;
                        this.input.level_one = res.level_one;
                        this.input.level_two = res.level_two;
                        this.input.level_three = res.level_three;
                        this.input.level_four = res.level_four;
                        this.input.level_five =  res.level_five;
                    });
                }
            },
			async getLevel() {
                try {
                    this.loading = true
                    const res = await this.$api.level.get(this.levelSelected, this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            quitEdit() {
                this.$router.push({ name: 'levelList' })
            },
			save() {
                this.saveLevel().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'levelList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                });
			},
			async saveLevel() {
                try {
                    let res;
                    this.saving = true
                    if (this.input.id) {
                        res = await this.$api.level.save( this.levelSelected, this.input );
                    } else {
                        res = await this.$api.level.new( this.levelSelected, this.input );
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            getLevelLists() {
                if (this.levelSelected !== 1 ) {
                    this.getLevels(1).then(res => this.level1List = res);
                }
                if (this.maxLevel >= 2 && this.levelSelected !== 2 ) {
			        this.getLevels(2).then(res => this.level2List = res);
                }
                if (this.maxLevel >= 3 && this.levelSelected !== 3 ) {
			        this.getLevels(3).then(res => this.level4List = res);
                }
                if (this.maxLevel >= 4 && this.levelSelected !== 4 ) {
			        this.getLevels(4).then(res => this.level4List = res);
                }
                if (this.maxLevel >= 5 && this.levelSelected !== 5 ) {
			        this.getLevels(5).then(res => this.level5List = res);
                }
            },
			async getLevels(level) {
                try {
                    this.loading = true
                    const res = await this.$api.level.list(level);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
		},
		mounted () {
            this.levelSelected = this.$router.currentRoute.params.lvl
            this.form();
			this.getLevelLists();
            this.success = true;
		},
        beforeRouteUpdate (to, from, next) {
            this.levelSelected = to.params.lvl
            this.form();
			this.getLevelLists();
            this.success = true;
            next()
        }
	}
</script>