import Vue from 'vue'
import App from './App.vue'
import store from './services/store'
import router from './router/'
import axios from 'axios'
import Vuetify from 'vuetify/lib'
import DatetimePicker from 'vuetify-datetime-picker'

Vue.use(Vuetify)
import VueSweetalert2 from 'vue-sweetalert2';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle as farTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import moment from 'moment'
Vue.config.productionTip = false
library.add(faChevronLeft)
library.add(faChevronRight)
library.add(faChevronDown)
library.add(faBars)
library.add(faTimes)
library.add(farTimesCircle)
library.add(faTrash)
library.add(faPlus)
library.add(faEdit)
Vue.use(DatetimePicker)


router.afterEach((to, from) => {
  store.dispatch('inspectToken');
});

Vue.use(VueSweetalert2);
Vue.component('font-awesome-icon', FontAwesomeIcon)
import '@/assets/scss/main.scss';
import 'sweetalert2/dist/sweetalert2.min.css';
import vuetify from './plugins/vuetify';
import wormsApi from './services/wormsApi'
//Layouts
import Crud from "./layout/crud.vue";
import Simple from "./layout/simple.vue";
import Empty from "./layout/empty.vue";

Vue.component("crud-layout", Crud);
Vue.component("simple-layout", Simple);
Vue.component("empty-layout", Empty);
//Filters
Vue.filter('round', function(value, decimals) {
  if(!value) {
    value = 0;
  }

  if(!decimals) {
    decimals = 0;
  }

  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return value;
});
Vue.filter('datetimestamp', function(value) {
  var parts = value.split('T');
  var date = parts[0];
  var time = parts[1];
  
  date = date.split('-');
  time = time.split(':');
    
  if(parseInt(time[0], 10) > 12) {
    var hour = parseInt(time[0], 10) % 12;
  }
  else {
   var hour = parseInt(time[0], 10);
  }
    
  hour = hour < 10 ? '0' + hour : hour;
    
  return date[0] + '/' + date[1] + '/' + date[2] + ' ' + hour + ':' + time[1];
});
Vue.filter('moment_dtstamp', function(date) {
    return moment(date).format('YYYY-MM-DD h:mma');
});
Vue.prototype.$api = wormsApi
Vue.prototype.$moment = moment

Vue.mixin({
  data: function() {
    return {
      get icongraphicPlaceholder() {
        return process.env.VUE_APP_API+'/static/placeholder/icongraphic.png';
      }
    }
  }
})

axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'
new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
