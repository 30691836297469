<template>
	<v-row style="background-color:#313a3a" class="vh-100" align="center" justify="center">
		<v-col cols="12" sm="12" md="12" lg="8">
			<v-card class="elevation-12">
				<v-card
				>
					<div class="d-flex flex-sm-column flex-md-row flex-no-wrap justify-space-between">
						<v-card-text>
							<v-form @submit.stop.prevent="register()" class="text-left pt-8 pb-8 pl-4 pr-4 ">
								<v-alert v-if="alertText" type="warning">
									{{alertText}}
								</v-alert>
      							<v-expand-transition>
									<div v-if="status == 'static'">
                                        <div class="display-1 mb-6">
                                            Register
                                        </div>
                                        <v-text-field v-model="input.username" label="Username" name="username" type="text" />
                                        <v-text-field v-model="input.email" label="Email" name="email" type="email" />
                                        <v-text-field v-model="input.password" id="password" label="Password" name="password" type="password" />
                                        <v-text-field v-model="input.password_confirm" id="password" label="Confirm Password" name="password" type="password" />
                                        <v-spacer />
                                        <v-btn class="mt-2" color="primary" type="submit"><v-icon class="mr-2">mdi-login-variant</v-icon>Register</v-btn>
                                    </div>
								</v-expand-transition>
								<div v-if="status == 'loading'">
									<v-progress-circular
									class="ml-2"
									:size="60"
									:width="4"
									indeterminate
									color="primary"
									></v-progress-circular>
								</div>
								<div v-if="status == 'success'">
                                    <div>{{progressText}}</div>
								</div>
							</v-form>
						</v-card-text>
					</div>
				</v-card>
			</v-card>
		</v-col>
	</v-row>
</template>
<style scoped>
.v-icon {
	font-size: 30px;
}
</style>
<script>
	export default {
		data() {
			return {
				progressText: 'Verifying your email now...',
				status: 'static',
                verifyObj: {},
                alertText: '',
                input: {
                    username: '',
                    email: '',
                    password: '',
                    password_confirm: '',
                }
			}
		},
		computed: {

		},
		methods: {
            register(){
                this.registerUser().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
						this.status = 'success';
                    	this.progressText = 'An email has been sent to your email for verification. You may close this window.';
                    }
                })
            },
			async registerUser() {
                try {
                    let res;
                    this.status = 'loading';
                    this.saving = true
					res = await this.$api.accountProfile.register( this.input );
                    return res;
                } catch (error) {
                    this.status = 'static';
                    this.alertText = response.data.detail
                    // handle error
                } finally {
                    this.saving = false
                }
            },
			goToLogin() {
                this.$router.push({ name: 'login' })
			}
		},
		mounted() {
			// this.verifyObj = this.$route.query;
			// setTimeout(() => this.verify(), 2000);
		}
	}
</script>