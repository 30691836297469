<template>
    <v-container fluid class="list-recurrence">
        <v-row>
        <v-col cols="12">
            <div class="mb-5 mx-auto text-right">
                <v-btn @click="removeFilter()" v-if="filter" color="secondary" text dark class="mr-2">
                    <v-icon>mdi-window-close</v-icon>
                    Remove filter
                </v-btn>
                <v-dialog v-model="filterDialog" max-width="400">
                    <template v-slot:activator="{on}">
                        <v-btn color="secondary" dark v-on="on" class="mr-2">
                            <v-icon>mdi-filter</v-icon>
                            Filter
                        </v-btn>
                    </template>
                    <v-card>
                        <tableFilter :filterFields="filterData" :systemList="systemList" v-on:filteredData="tableFilter"></tableFilter>
                    </v-card>
                </v-dialog>
                <v-btn @click="edit()" dark color="primary">
                    <v-icon>mdi-plus</v-icon>
                    New
                </v-btn>
            </div>
            <v-data-table
            v-if="recurrenceList"
            :loading="loading" loading-text="Loading... Please wait"
            :headers="headers"
            :items="recurrenceTable"
            :sort-by="['id']"
            :server-items-length="pagination.count"
            hide-default-footer
            class="elevation-1"
            >
            <template v-slot:item="row">
              <tr>
                <td class="text-left">{{row.item.id}}</td>
                <td class="text-left">{{row.item.name}}</td>
                <td style="width:150px" class="text-xs-center">
                    <v-btn-toggle>
                        <v-btn small fab icon color="grey"  @click="edit(row.item.id)">
                        <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn small fab icon color="grey" @click="remove(row.item.id)" >
                        <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center">
              <v-pagination total-visible="7" class="mt-2 mb-2" v-model="pagination.current" :length="pagination.pages"></v-pagination>
              <div class="subtitle">Total: {{pagination.count}}</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import tableFilter from '@/components/filter/filterRecurrence.vue'
	export default {
        components: {
            tableFilter,
        },
		data: () => ({
			success: false,
			loading: true,
            headers: [
            { text: 'ID', align: 'left', sortable: true, value: 'id', width: 100},
            { text: 'Name', value: 'name' },
            { text: '', sortable: false,}
            ],
			recurrenceList: [],
            filter: false,
            filterDialog: false,
            filterData: {
                is_active: true
            },
            filteredRecurrence: [],
            pagination:{
                count:0,
                pages: 1,
                current:1,
            },
            perPage: 20,
		}),
        computed: {
            recurrenceTable: function() {
                if (Object.keys(this.filterData).length > 0 && this.filter == true) {
                    return this.filteredRecurrence
                } else {
                    return this.recurrenceList;
                }
            }
        },
		methods: {
            // Main Function
            list() {
			    this.getRecurrence(1).then((res) => {
                    this.recurrenceList = res.results;
                    this.pagination.next = res.next
                    this.pagination.count = res.count
                    this.pagination.pages = Math.ceil(res.count / this.perPage)
                });
            },
			async getRecurrence(page) {
                try {
                    this.loading = true
                    const res = await this.$api.recurrence.search({ is_active: this.filterData.is_active, limit:this.perPage, offset: this.perPage*(page - 1) });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			edit(id) {
                this.$router.push({ name: 'recurrenceForm', params: { id } })
            },
            remove(id) {
                this.$swal({
                    icon: 'warning',
                    title: 'Are you sure to delete this?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
			            this.$api.recurrence.delete(id).then(res => this.list() );
                    }
                })
            },// Additional Infos
            tableFilter(value) {
                this.filter = true;
                if (Object.keys(value).length > 0) {
                    // this.loading = true;
                    this.filterData = value;
			        this.$api.recurrence.search(value).then((res) => {
                        this.filteredRecurrence = res.data;
                        if (this.filterData.name) {
                            this.filteredRecurrence = this.filteredRecurrence.filter(recurrence => 
                                recurrence.name.toLowerCase().includes(this.filterData.name.toLowerCase())
                            )
                        }
                    });
                }
                setTimeout(() => this.loading = false, 2000 );
                this.filterDialog = false;
            },
            removeFilter() {
                this.filterData = {};
                this.filteredAsset = [];
                this.filter = false;
            },
		},
		mounted () {
			this.list();
		},
        watch: {
            pagination: {
                handler(val){
                    this.getRecurrence(this.pagination.current).then((res) => {
                        this.recurrenceList = res.results;
                        this.pagination.next = res.next
                        this.pagination.count = res.count
                        this.pagination.pages = Math.ceil(res.count / this.perPage)
                    });
                },
                deep: true
            },
        },
	}
</script>