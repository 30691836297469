<template>
	<div id="header">
        <v-app-bar
        app
        color="white"
        >
        <v-row align="center">
            <v-col cols="4" class="text-left">
                <v-app-bar-nav-icon @click="toggleDrawer" />
                <v-btn color="secondary" small to="/checklist-checks">
                    <span class="d-inline d-sm-none"><v-icon>mdi-clipboard-text-outline</v-icon></span>
                    <span class="d-none d-sm-inline">Check / Verify</span>
                </v-btn>
            </v-col>
            <v-col cols="4">
                <v-toolbar-title>
                    {{currentRouteName}} {{currentRoutePageId}}
                    <transition name="fade">
                    <v-progress-circular v-if="tokenCheck"
                    class="ml-2"
                    :size="16"
                    :width="2"
                    indeterminate
                    color="primary"
                    ></v-progress-circular>
                    </transition>
                </v-toolbar-title>
            </v-col>
            <v-col cols="4" class="text-right">
                <v-tooltip v-if="system_mode == 'set_up'" bottom class="mr-4" >
                <template v-slot:activator="{ on }">
                    <v-icon small color="grey" dark v-on="on">mdi-wrench</v-icon>
                </template>
                <span>Currently in set up mode</span>
                </v-tooltip>
                <v-tooltip v-if="system_mode == 'production'" bottom class="mr-4" >
                <template v-slot:activator="{ on }">
                    <v-icon small color="grey" dark v-on="on">mdi-cog</v-icon>
                </template>
                <span>Currently in production mode</span>
                </v-tooltip>
                <v-tooltip v-if="system_mode == 'file_import'" bottom class="mr-4" >
                <template v-slot:activator="{ on }">
                    <v-icon small color="grey" dark v-on="on">mdi-database-import</v-icon>
                </template>
                <span>Currently in file import mode</span>
                </v-tooltip>
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn
                        icon
                        color="primary"
                        dark
                        v-on="on"
                        >
                        <v-avatar color="indigo" size="36">
                            <span class="white--text">{{profileFirstLetter}}</span>
                        </v-avatar>
                        </v-btn>
                    </template>
                    <v-list class="text-left" rounded dense>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>{{profile.username}}</v-list-item-title>
                                <v-list-item-subtitle>{{profile.email}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-for="(link, index) in userLinks" v-bind:key="index" @click="userLink(link.link)" :disabled="link.disabled">
                            <v-list-item-icon size="12" v-if="link.icon">
                                <v-icon>{{link.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{link.title}}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
    </v-app-bar>
    </div>
</template>
<script>
	export default {
        props: {
            drawerStatus: Boolean,
            globalPref: Array,
        },
		data: () => ({
            userLinks:[
                {
                    icon: 'mdi-account',
                    title: 'My Profile',
                    link: 'profile',
                    disabled: true,
                },
                {
                    icon: 'mdi-logout',
                    title: 'Logout',
                    link: 'logout'
                }
            ],
            tokenCheck: false,
            profile: [],
		}),
        computed: {
			authed: function() {
				return this.$store.getters.isLoggedIn
			},
			profileFirstLetter() {
                if (Object.keys(this.profile).length > 0) {
				    return this.profile.username.charAt(0).toUpperCase();
                }
            },
            currentRouteName() {
                return this.$route.meta.title;
            },
            currentRoutePageId() {
                return this.$route.params.lvl;
            },
			system_mode: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'worms_mode').value);
				}
			}
        },
        methods: {
            getProfileId() {
			    this.getProfile().then(res => this.profile = res);
            },
			async getProfile() {
                try {
					const res = await this.$api.accountProfile.get();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			checkTokenPeriodic() {
				this.tokenCheck = true;
				this.$store.dispatch('inspectToken').then((res) => {
				if (res === false) {
					setTimeout(() => this.tokenCheck = false, 1000 );
				} else {
				};
				})
			},
            toggleDrawer() {
                this.$emit('update', !this.drawerStatus);
            },
            userLink(link) {
                if (link == 'logout') {
                    this.$store.dispatch('logout');
                }
                if (link == 'profile') {
                    this.$router.push({ name: 'profileForm', params: { id: this.profile.id  } })
                }
            }
        },
		mounted: function () {
			this.getProfileId();
			setInterval(() => {
				this.checkTokenPeriodic() 
			}, 30000);
        },
		watch: {
			windowWidth(width) {
				if (width >= 1264) {
					this.drawer = true;
				} else {
					this.drawer = false;
				}
			}
		}
    }
</script>