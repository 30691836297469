<template>
  <div>
        <v-content>
            <slot />
        </v-content>
  </div>
</template>
<script>
export default {
    components: {
    },
    data: () => ({
    }),
    computed: {
    },
    methods: {
    },
    watch: {
    },
    mounted: function () {
    }
};
</script>
