<template>
	<v-row style="background-color:#313a3a" class="vh-100" align="center" justify="center">
		<v-col cols="12" sm="12" md="12" lg="8">
			<v-card class="elevation-12">
				<v-card
				>
					<div class="d-flex flex-sm-column flex-md-row flex-no-wrap justify-space-between">
						<v-img class="w-100 w-md-50" src="img/login-image.jpg"></v-img>
						<v-card-text>
							<v-form @submit.stop.prevent="login()" class="text-left pt-8 pb-8 pl-4 pr-4 vh-md-50 vh-50 h-auto">
								<v-alert v-if="alertText" type="warning">
									{{alertText}}
								</v-alert>
      							<v-expand-transition>
									<div v-if="loginStatus == 'not'">
										<div class="display-1 mb-6">
											Login
										</div>
										<v-text-field v-model="username" label="Login" name="login" type="text" />
										<v-text-field v-model="password" id="password" label="Password" name="password" type="password" />
										<v-spacer />
										<v-btn class="mt-2" color="primary" type="submit"><v-icon class="mr-2">mdi-login-variant</v-icon>Login</v-btn>
										<br>
										<router-link to="register" class="d-inline-block mt-2" style="text-decoration:none">Don't have an account? Register here.</router-link>
									</div>
								</v-expand-transition>
								<div v-if="loginStatus == 'loging'">
									<v-progress-circular
									class="ml-2"
									:size="60"
									:width="4"
									indeterminate
									color="primary"
									></v-progress-circular>
								</div>
							</v-form>
						</v-card-text>
					</div>
				</v-card>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
	export default {
		data() {
			return {
				username: '',
				password: '',
				alertText: '',
				redirect: false,
				loginStatus: 'not'
			}
		},
		computed: {
			expiredLocation: function() {
				return this.$store.getters.expiredLocation
			},
		},
		methods: {
			login() {
				this.loginStatus = 'loging';
				this.$store.dispatch('obtainToken', [this.username, this.password]).then((response) => {
					this.loginStatus = 'done';
					if (response.statusText == "OK") {
						this.$api.accountProfile.get().then((res) => {
							if (this.expiredLocation) {
								this.$router.push({ name: this.expiredLocation.name, params: this.expiredLocation.params })
							} else {
								if (res.data.role == 'STAFF') {
									// this.$router.push({ name: 'home' })
									this.$router.push({ name: 'checkVerifyList' }) //disabled from MFS
								}
								if (res.data.role == 'SYSADMIN') {
									this.$router.push({ name: 'home' })
								}
							}
						});
					} else {
						this.loginStatus = 'not';
						this.alertText = response.data.detail
					}
				})
			}
		},
		mounted() {
			if (this.$router.currentRoute.name == 'home') {
				this.redirect = true;
			}
		}
	}
</script>