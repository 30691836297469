<template>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
            <div class="py-2 px-4 text-left command-button-list">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon>mdi-arrow-left</v-icon>
                    Back
                </v-btn>
            </div>
            <v-card class="pa-3 mb-3 mx-auto text-right">
            <userForm v-if="!loading" :userId="userId" v-on:return-form="quitEdit"></userForm>
            <v-divider light></v-divider>
            <teamTable v-if="!loading" :userId="userId" v-on:return-form="quitEdit"></teamTable>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import teamTable from '@/components/table/team-table.vue';
import userForm from '@/components/form/user-form.vue';
	export default {
		  components: {
              teamTable,
              userForm
		  },
        props: {
            id: Number   
        },
		data: () => ({
            loading: true,
            userId: 0
		}),
		methods: {
            // Main Function
			quitEdit() {
                this.$router.push({ name: 'userList' })
            },
		},
		mounted () {
            this.userId = this.id;
            this.loading = false;
		},
	}
</script>