<template>
    <div>
        <v-list dense class="text-left">
            <v-list-item link to="/">
                <v-list-item-action>
                    <v-icon>mdi-home</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Dashboard</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/checklists">
                <v-list-item-action>
                    <v-icon>mdi-format-list-checkbox</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Checklist</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/checkplans">
                <v-list-item-action>
                    <v-icon>mdi-format-list-checks</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Checkplans</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/assets">
                <v-list-item-action>
                    <v-icon>mdi-application</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Asset</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/components">
                <v-list-item-action>
                    <v-icon>mdi-video-input-component</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Component</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list dense class="text-left" v-if="this.globalPref">
            <v-list-group
                prepend-icon="mdi-map-marker"
                color="white--text"
            >
                <template v-slot:activator>
                <v-list-item-title>LOCATION</v-list-item-title>
                </template>

                <v-list-item link :to="'/levels/'+level" v-for="(level,index) in maxLevel" v-bind:key="index">
                    <v-list-item-action>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ getLevelName('level_'+ indexName[level] +'_label') || 'Level 1' }} </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
            
        </v-list>
        <v-divider></v-divider>
        <v-list dense class="text-left">
            <v-list-group
                prepend-icon="mdi-cogs"
                color="white--text"
            >
                <template v-slot:activator>
                <v-list-item-title>SETTINGS</v-list-item-title>
                </template>
                
                <v-list-item link to="/teams">
                    <v-list-item-action>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Team</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link to="/users">
                    <v-list-item-action>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>User</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link to="/user-preference">
                    <v-list-item-action>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>User Preference</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link to="/global-preference">
                    <v-list-item-action>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Global Preference</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>

        </v-list>
        <v-divider></v-divider>
        <v-list dense class="text-left">

            <v-list-group
                prepend-icon="mdi-file-compare"
                color="white--text"
            >
                <template v-slot:activator>
                <v-list-item-title>TEMPLATES</v-list-item-title>
                </template>
                <v-list-item link to="/recurrences">
                    <v-list-item-action>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Recurrance</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link to="/base-assets">
                    <v-list-item-action>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Base Asset</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!-- <v-list-item link to="/base-component">
                    <v-list-item-action>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Base Component</v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->
                <v-list-item link to="/master-components">
                    <v-list-item-action>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Master Component</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link to="/base-units">
                    <v-list-item-action>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Base Unit</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link to="/units">
                    <v-list-item-action>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Unit</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link to="/master-parameters">
                    <v-list-item-action>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Master Parameter</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link to="/systems">
                    <v-list-item-action>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>System</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
            
        </v-list>
        <v-divider></v-divider>
    </div>
</template>
<script>
	export default {
		  components: {
		  },
		data: () => ({
            indexName: ['', 'one', 'two', 'three', 'four', 'five'],
        }),
        props: {
            globalPref: Array
        },
        computed: {
			maxLevel: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'maximum_level').value);
				} else {
                    return 5;
                }
			}
        },
        methods: {
            getLevelName(levelLabel) {
                if ( this.globalPref.length > 0 && levelLabel !== undefined) {
                    return this.globalPref.find(pref => pref.name === levelLabel).value;
                }
            },
        },
		mounted: function () {
        },
    }
</script>