<template>
  <div>
      <Header v-if="authed" :drawerStatus="drawer" @update="drawer = $event;" :globalPref="globalPref"></Header>
        <v-content v-if="authed" >
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <slot />
                    </v-col>
                </v-row>
            </v-container>
        </v-content>
  </div>
</template>
<script>
import login from '@/pages/login';
import Header from '@/components/common/headerCheckerVerifier.vue';
import Menu from '@/components/common/menuCheckerVerifier.vue';
export default {
    components: {
        Header,
        Menu,
        login,
    },
    data: () => ({
        drawer: false,
        windowWidth: 0,
        globalPref: [],
    }),
    computed: {
        companyName: function() {
            if (this.globalPref.length > 0) {
                return (this.globalPref.find(pref => pref.name === 'organization_name').value);
            }
        },
        authed: function() {
            return this.$store.getters.isLoggedIn
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        getGlobalPref() {
            this.getGlobalPreferences().then(res => this.globalPref = res);
        },
        async getGlobalPreferences() {
            try {
                const res = await this.$api.preference.global();
                return res.data;
            } catch (error) {
            } finally {
            }
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        checkLogin() {
            if (!this.authed) {
			    this.$router.push('/login');
            }
        }
    },
    watch: {
        windowWidth(width) {
            if (width >= 1264) {
                this.drawer = true;
            } else {
                this.drawer = false;
            }
        }
    },
    mounted: function () {
        this.getGlobalPref();
        this.checkLogin();
    }
};
</script>
