<template>
    <v-container fluid class="list-system">
        <v-row>
        <v-col cols="12">
            <div class="mb-5 text-right">
            <v-btn @click="removeFilter()" v-if="filter" color="secondary" text dark class="mr-2">
                <v-icon>mdi-window-close</v-icon>
                Remove filter
            </v-btn>
            <v-dialog v-model="filterDialog" max-width="400">
                <template v-slot:activator="{on}">
                    <span v-on="on">
                        <v-btn v-if="loading == false" color="secondary" dark class="mr-2">
                            <v-icon>mdi-filter</v-icon>
                            Filter
                        </v-btn>
                    </span>
                </template>
                <v-card>
                     <tableFilter tableType="asset" :globalPref="globalPref" :filterFields="filterData" :level1List="level1List" :level2List="level2List" :level3List="level3List" :level4List="level4List" :level5List="level5List" :baseAssetList="baseAssetList" v-on:filteredData="tableFilter"></tableFilter>
                </v-card>
            </v-dialog>
            <v-btn @click="edit()" dark color="primary">
                <v-icon>mdi-plus</v-icon>
                New
            </v-btn>
            </div>
            <v-data-table
            v-if="assetList"
            :loading="loading" loading-text="Loading... Please wait"
            :headers="headers"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items="assetTable"
            :server-items-length="pagination.count"
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:item="row">
              <tr>
                <td class="text-left">{{row.item.id}}</td>
                <td class="text-left">{{row.item.name}}</td>
                <td class="text-left" v-if="row.item">{{  getBaseAssetName(row.item.base_asset) }}</td>
                <td class="text-left">
                    <span v-if="row.item.level_one">{{  getLevel1Name(row.item.level_one) }} </span>
                    <span v-if="row.item.level_two"> > {{  getLevel2Name(row.item.level_two) }}</span> 
                    <span v-if="row.item.level_three"> > {{  getLevel3Name(row.item.level_three) }} </span> 
                    <span v-if="row.item.level_four"> > {{  getLevel4Name(row.item.level_four) }} </span> 
                    <span v-if="row.item.level_five"> > {{  getLevel5Name(row.item.level_five) }}</span> 
                </td>
                <td style="width:150px" class="text-xs-center">
                    <v-btn-toggle>
                        <v-btn small fab icon color="grey"  @click="edit(row.item.id)">
                        <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn small fab icon color="grey" @click="remove(row.item.id)" >
                        <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center">
              <v-pagination total-visible="7" class="mt-2 mb-2" v-model="pagination.current" :length="pagination.pages"></v-pagination>
              <div class="subtitle">Total: {{pagination.count}}</div>
          </div>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import tableFilter from '@/components/filter/filterAsset.vue'
	export default {
		  components: {
              tableFilter,
		  },
		data: () => ({
            headers: [
            { text: 'ID', align: 'left', sortable: true, value: 'id', width: 100},
            { text: 'Name', value: 'name' },
            { text: 'Base Asset', value: 'base_asset' },
            { text: 'Location', value: 'location', sortable: false,},
            { text: ''}
            ],
            pagination:{
                count:0,
                pages: 1,
                current:1,
            },
            perPage: 20,
			success: false,
			assetList: [],
			loading: true,
            filter: false,
            filterDialog: false,
            filterData: {},
            filteredAsset: [],
            baseAssetList: [],
            level1List: [],
            level2List: [],
            level3List: [],
            level4List: [],
            level5List: [],
        }),
        props: {
            globalPref: {
                type: Array,
                required: false
            },
        },
        computed: {
			maxLevel: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'maximum_level').value);
				} else {
                    return 5;
                }
			},
            assetTable: function() {
                if (Object.keys(this.filteredAsset).length >= 0 && this.filter == true) {
                    return this.filteredAsset
                } else {
                    return this.assetList;
                }
            },
        },
		methods: {
            // Main Function
            list() {
			    this.getAssets(1).then((res) => {
                    this.assetList = res.results;
                    this.pagination.next = res.next
                    this.pagination.count = res.count
                    this.pagination.pages = Math.ceil(res.count / this.perPage)
                });
            },
			async getAssets(page) {
                try {
                    this.loading = true
                    const res = await this.$api.asset.search({ limit:this.perPage, offset: this.perPage*(page - 1) });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			edit(id) {
                this.$router.push({ name: 'assetForm', params: { id } })
            },
            tableFilter(value) {
                this.filter = true;
                if (Object.keys(value).length > 0) {
                    this.filterData = value;
			        this.$api.asset.search(value).then((res) => {
                        console.log(res)
                        this.filteredAsset = res.data;
                        if (this.filterData.name) {
                            this.filteredAsset = this.filteredAsset.filter(asset => 
                                asset.name.toLowerCase().includes(this.filterData.name.toLowerCase())
                            )
                        }
                    });
                }
                setTimeout(() => this.loading = false, 2000 );
                this.filterDialog = false;
            },
            removeFilter() {
                this.filterData = {};
                this.filteredAsset = [];
                this.filter = false;
            },
            remove(id) {
                this.$swal({
                    icon: 'warning',
                    title: 'Are you sure to delete this?',
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.value) {
			            this.$api.asset.delete(id).then(res => this.list() );
                    }
                })
            },
            getAdditionalData() {
			    this.getBaseAssets().then(res => this.baseAssetList = res);
            },
			async getBaseAssets() {
                try {
                    const res = await this.$api.baseAsset.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getBaseAssetName(id) {
                if ( this.baseAssetList.length !== 0 && id ) {
                return this.baseAssetList.find(baseAsset => baseAsset.id === id).name;
                }
            },
            getLevelLists() {
                this.getLevels(1).then(res => this.level1List = res);
                if (this.maxLevel >= 2) {
			        this.getLevels(2).then(res => this.level2List = res);
                }
                if (this.maxLevel >= 3 ) {
			        this.getLevels(3).then(res => this.level4List = res);
                }
                if (this.maxLevel >= 4) {
			        this.getLevels(4).then(res => this.level4List = res);
                }
                if (this.maxLevel >= 5) {
			        this.getLevels(5).then(res => this.level5List = res);
                }
            },
			async getLevels(level) {
                try {
                    this.loading = true
                    const res = await this.$api.level.list(level);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            getLevel1Name(id) {
                if ( this.level1List.length !== 0 && id ) {
                    return this.level1List.find(level1 => level1.id === id).name;
                } else {
                    return;
                }
            },
            getLevel2Name(id) {
                if ( this.level2List.length !== 0 && id ) {
                    return this.level2List.find(level2 => level2.id === id).name;
                } else {
                    return;
                }
            },
            getLevel3Name(id) {
                if ( this.level3List.length !== 0 && id ) {
                    return this.level3List.find(level3 => level3.id === id).name;
                } else {
                    return;
                }
            },
            getLevel4Name(id) {
                if ( this.level4List.length !== 0 && id ) {
                    return this.level4List.find(level4 => level4.id === id).name;
                } else {
                    return;
                }
            },
            getLevel5Name(id) {
                if ( this.level5List.length !== 0 && id ) {
                    return this.level5List.find(level5 => level5.id === id).name;
                } else {
                    return;
                }
            },
		},
		mounted () {
            this.list();
            this.getAdditionalData();
            this.getLevelLists();
            this.success = true;
		},
        watch: {
            pagination: {
                handler(val){
                    this.getAssets(this.pagination.current).then((res) => {
                        this.assetList = res.results;
                    });
                },
                deep: true
            },
        },
	}
</script>