<template>
	<div id="app">
		<v-app>
    		<component :is="layout" :globalPref="globalPref">
				<transition name="fade">
					<router-view :profileId='profileId' :globalPref='globalPref' />
				</transition>
				<alertDisconnect v-if="tokenError" ></alertDisconnect>
				<v-snackbar
				v-model="apiError"
				:timeout="0"
				>
				{{ apiErrorText }}
				<v-btn
					color="white"
					text
					@click="$store.commit('apiError', '')"
				>
					Ok
				</v-btn>
				</v-snackbar>
    		</component>
		</v-app>
	</div>
</template>

<script>
	import alertDisconnect from '@/components/alerts/alertDisconnect.vue';
	const default_layout = "default";

	export default {
		components: {
			alertDisconnect,
			// Footer,
		},
		name: 'App',
		data: () => ({
			drawer: false,
			apiRetry: false,
			apiRefresh: true,
			globalPref: [],
			windowWidth: 0,
		}),
		computed: {
			layout: function () {
				return (this.$route.meta.layout || default_layout) + "-layout";
			},
			tokenError: function () {
				return this.$store.getters.tokenError;
			},
			apiError: function () {
				return this.$store.getters.apiError;
			},
			apiErrorText: function () {
				return this.$store.getters.apiErrorText;
			},
			authed: function() {
				return this.$store.getters.isLoggedIn;
			},
			profileId: function() {
				return this.$store.getters.profileId;
			}
		},
		mounted: function () {
			this.apiRefresh = false;
			
            this.$store.dispatch('inspectToken');
			if (this.authed == false) {
				// if (this.$route.name !== 'home') {
				// 	this.$router.push('/');
				// } 
			} else {
				this.getGlobalPref();
				this.getProfileId();
			}
		},
		created() {
			window.addEventListener('resize', this.handleResize);
			this.handleResize();
		},
		destroyed() {
			window.removeEventListener('resize', this.handleResize);
		},
		methods: {
            getGlobalPref() {
				if (this.authed == true) {
			    	this.getGlobalPreferences().then(res => this.globalPref = res);
				}
            },
			async getGlobalPreferences() {
                try {
                    const res = await this.$api.preference.global();
                    return res.data;
                } catch (error) {
                } finally {
                }
            },
            getProfileId() {
			    this.getProfile().then((res) => {
					this.$store.dispatch('setProfileId', res.id).then((res) => {
					})
				});
            },
			async getProfile() {
                try {
					const res = await this.$api.accountProfile.get();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			toggleDrawer(drawer) {
				this.drawer = drawer;
			},
			handleResize() {
				this.windowWidth = window.innerWidth;
			},
			checkTokenPeriodic() {
				this.tokenCheck = true;
				this.$store.dispatch('inspectToken').then((res) => {
				if (res === false) {
					setTimeout(() => this.tokenCheck = false, 1000 );
				} else {
				};
				})
			}
		},
		watch: {
            '$route' (to, from) {
				this.getGlobalPref();
			},
			windowWidth(width) {
				if (width >= 1264) {
					this.drawer = true;
				} else {
					this.drawer = false;
				}
			}
		}
	};
</script>