import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import router from '../router'
Vue.use(Vuex)
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'

export default new Vuex.Store({
	namespaced: true, // Add this here
	state: {
		isLoggedIn: false,
		jwt: localStorage.getItem('token'),
		refreshToken: localStorage.getItem('refresh'),
		profileId: null,
		tokenError: false,
		apiError: false,
		apiErrorText: '',
		expiredLocation: '',
		endpoints: {
		  obtainJWT: process.env.VUE_APP_API+'/api/token/',
		  refreshJWT: process.env.VUE_APP_API+'/api/token/refresh/'
		},
        cancelTokens: [],
	},

	getters: {
		jwt: state => {
			return state.jwt
		},
		tokenError: state => {
			return state.tokenError
		},
		apiError: state => {
			return state.apiError
		},
		apiErrorText: state => {
			return state.apiErrorText
		},
		expiredLocation: state => {
			return state.expiredLocation
		},
		isLoggedIn: state => {
			if (state.jwt) {
				return true
			} else {
				return false
			}
		},
		profileId:state => {
			return state.profileId
		},
        cancelTokens(state) {
            return state.cancelTokens;
        }
	},

	mutations: {
		// To update token / renewable token
		updateToken(state, [newToken, refreshToken]){
		  localStorage.setItem('token', newToken);
		  state.jwt = newToken;
		  if (refreshToken !== null) {
			localStorage.setItem('refresh', refreshToken);
			state.refreshToken = refreshToken;
		  }
		  state.isLoggedIn = true;
		},
		// To remove token
		removeToken(state){
		  localStorage.removeItem('token');
		  localStorage.removeItem('refresh');
		  state.jwt = null;
		  state.refreshToken = null;
		  state.isLoggedIn = false;
		  state.tokenError = false;
		},
		// To display token error
		tokenError(state){
		  state.tokenError = true;
		},
		// Api Error
		apiError(state, error){
		  state.apiErrorText = error;
		  if (error !== '') {
			state.apiError = true;
		  } else {
		  	state.apiError = false;
		  }
		},
		setProfileId(state, profile_id){
			state.profileId = profile_id
		},
		expiredLocation(state, loc){
			state.expiredLocation = loc
		},
        ADD_CANCEL_TOKEN(state, token) {
            state.cancelTokens.push(token);
        },
        CLEAR_CANCEL_TOKENS(state) {
            state.cancelTokens = [];
        }

	},

	actions: {
		// To get token
		obtainToken({commit}, [username, password]){
		  const payload = {
			username: username,
			password: password
		  }
		return axios.post(this.state.endpoints.obtainJWT, payload)
			.then((response)=>{
				axios.defaults.headers.common['Authorization'] = 'Bearer '+response.data.access
				this.commit('updateToken', ['Bearer '+response.data.access, response.data.refresh]);
				return response;
			  })
			.catch((error)=>{
				return error.response
			  })
		},
		// To show token error
		tokenError() {
			this.commit('tokenError');
		},
		apiError({commit}, apiErrorText) {
			this.commit('apiError', apiErrorText);
		},
		// To renew token
		refreshToken(){
		  const payload = {
			refresh: this.state.refreshToken
		  }
			axios.post(this.state.endpoints.refreshJWT, payload)
			  .then((response)=>{
				  console.log('renew and refresh')
				  axios.defaults.headers.common['Authorization'] = 'Bearer '+response.data.access
				  this.commit('updateToken', ['Bearer '+response.data.access, null])
				  setTimeout(function(){ router.go() }, 500);
				})
			  .catch((error)=>{
				  if (error.response.status == '401') {
					  console.log('logout and go to home')
					  this.commit('removeToken');
					  router.push('/login');
				  }
			  })
		},
		logout() {
			this.commit('removeToken');
			router.push('/login');
		},
		// To inspect token for renewal or relogin
		inspectToken(){
			const token = this.state.jwt;
			if(token){
			  const decoded = jwt_decode(token);
			  const exp = decoded.exp
			  	if(((Date.now()/1000) - exp) < 100 ){
					if(!this.state.refreshToken) {
						this.commit('expiredLocation', router.currentRoute);
						this.commit('removeToken');
						router.push('/login');
					}
				} else if(((Date.now()/1000) - exp) > 100 ){
					if(!this.state.refreshToken) {
						this.commit('expiredLocation', router.currentRoute);
						this.commit('removeToken');
						router.push('/login');
					} else {
						this.dispatch('refreshToken');
					}
				} else if(((Date.now()/1000) - exp) > 2000 ) {
					this.commit('expiredLocation', router.currentRoute);
					this.commit('removeToken');
					router.push('/login');
				}
				return false;
			}
		  },
		  setProfileId({commit}, profile_id){
			this.commit('setProfileId', profile_id);
		  },
		  expiredLocation({commit}, loc){
			this.commit('expiredLocation', loc);
		  },

		  CANCEL_PENDING_REQUESTS(context) {

            // Cancel all request where a token exists
            context.state.cancelTokens.forEach((request, i) => {
                if(request.cancel){
                    request.cancel();
                }
            });

            // Reset the cancelTokens store
            context.commit('CLEAR_CANCEL_TOKENS');
        }
	}
});