<template>
    <div>
        <div>
            <v-simple-table class="full-width">
                <template v-slot:default>
                <thead>
                    <tr>
                    <th class="text-left">Base Component</th>
                    <th class="text-left">Master Component</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(baseComps, index) in baseCompsbyAsset" :key="baseComps.id">
                    <td>
                    <v-text-field
                        v-model="baseComps.name" 
                        required
                    ></v-text-field>
                    </td>
                    <td>
                        <v-select
                        v-model="baseComps.master_component"
                        :items="mastCompsbySystem"
                        item-value="id"
                        name="item"
                        item-text="name"
                        label="Select"
                        data-vv-name="select"
                        required
                        ></v-select>
                    </td>
                    <td>
                        <v-btn-toggle>
                            <v-btn small fab @click="save(baseComps.id, baseComps.name, baseComps.master_component)">
                                <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                            <v-btn small fab @click="remove(index, baseComps.id)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
            <div><v-btn class="mr-4" @click="addBaseComp()">Add Base Component</v-btn></div>
        </div>
    </div> 
</template>
<script>
	export default {
        props: {
            baseAssetId: {
                type: Number
            },
            systemId: {
                type: Number
            }
        },
		data() {
            return {
                headersBaseParameters: [
                { text: 'Attribute', value: 'attribute' },
                { text: 'Unit', value: 'unit' },
                { text: 'Condition Mode', value:'condition'},
                { text: 'Auto Mode', value:'auto'},
                { text: '', value:''},
                ],
                conditions: [
                { text: 'manual', value: 'MANUAL' },
                { text: 'automatic', value: 'AUTOMATIC' },
                ],
                auto: [
                { text: 'exist', value: 'EXIST' },
                { text: 'lower', value: 'LOWER' },
                { text: 'upper', value: 'UPPER' },
                { text: 'range', value: 'RANGE' },
                ],
                baseCompsbyAsset:[],
                mastCompsbySystem: [],
            }
        },
        computed: {
            indexedBaseParameter () {
                return this.baseCompsbyAsset.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
            list() {
			    this.getBaseCompbyBaseAsset().then(res => this.baseCompsbyAsset = res);
            },
			async getBaseCompbyBaseAsset() {
                try {
                    const res = await this.$api.baseComp.search({ base_asset: this.baseAssetId });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getMastCompList() {
			    this.getMastCompbySystem().then(res => this.mastCompsbySystem = res);
            },
			async getMastCompbySystem() {
                try {
                    const res = await this.$api.mastComp.search({ system: this.systemId });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            addBaseComp() {
                this.baseCompsbyAsset.push({
                    id: '',
                    name: '',
                    system: ''
                })
            },
            save(id, name, master_component) {
                this.saveBaseComp(id, name, master_component).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            reverseButtons: true,
                            confirmButtonText: 'OK',
                        }).then((result) => {
                        })
                        this.list();
                    }
                });
			},
			async saveBaseComp(id, name, master_component) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.baseComp.save( {id: id, name: name, master_component: master_component});
                    } else {
                        res = await this.$api.baseComp.new({name: name, master_component: master_component, base_asset: this.baseAssetId });
                    }
                    return res;
                } catch (error) {
                    // handle error
                    console.log(error)
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id !== '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
			                this.$api.baseComp.delete(id).then(res => this.list() );
                        }
                    })
                } else {
                    this.$delete(this.baseCompsbyAsset, index)
                }
            },
            
        },
        mounted(){
            this.list();
            this.getMastCompList();
        }
    }
</script>