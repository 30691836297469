<template>
    <v-container fluid class="list-system">
      <v-row>
        <v-col cols="12">
            <div class="mb-5 text-left d-flex">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon>mdi-arrow-left</v-icon>
                    Back
                </v-btn>
            </div>
            <v-card class="pa-3 mb-3 mx-auto text-left">
                <v-tabs >
                <v-tabs-slider/>
                <v-tab>Base Asset</v-tab>
                <v-tab :disabled="!this.input.id">Child: Base Component</v-tab>
                <v-tab :disabled="!this.input.id">Child: Base Parameters</v-tab>

                <v-tab-item>
                    <v-container fluid >
                        <v-form lazy-validation
                        ref="form"
                        v-model="valid">
                            <v-row>
                                <v-col cols="12" sm="6" md="3">
                                    <v-text-field
                                        label="Name" 
                                        v-model="input.name"
                                        :rules="nameRules"
                                        :counter="50"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-text-field
                                        label="Abbreviation" 
                                        v-model="input.abbreviation"
                                        :rules="abbreRules"
                                        :counter="5"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-select
                                    :items="systemList" 
                                    item-value="id"
                                    name="item"
                                    item-text="name"
                                    label="System" 
                                    v-model="input.system"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="3">
                                    <v-select
                                    :items="unitList" 
                                    item-value="id"
                                    name="item"
                                    item-text="name"
                                    label="Capacity Unit" 
                                    v-model="input.capacity_unit"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-select
                                    :items="unitList" 
                                    item-value="id"
                                    name="item"
                                    item-text="name"
                                    label="Consumption Unit" 
                                    v-model="input.consumption_unit"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="3">
                                    <label>Icongraphic</label><br/>
                                    <v-img v-if="input.icongraphic" :src="input.icongraphic" width="180" height="180"></v-img>
                                    <v-img v-if="!input.icongraphic" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                                </v-col>
                                <v-col cols="6" md="3">
                                    <v-file-input
                                        ref="file"
                                        :rules="iconRules"
                                        accept="image/png, image/jpeg"
                                        placeholder="Upload"
                                        prepend-icon="mdi-camera"
                                        label="Add or Replace Icongraphic"
                                        v-model="newIcon"
                                    ></v-file-input>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="text-right">
                                    <v-btn class="mr-4" color="primary"  :disabled="!valid" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-tab-item>
                <v-tab-item>
                    <div class="pa-2">
                        <div class="subtitle text-left mb-3">Base Components in this Base Asset</div>
                        <baseComponentForm :baseAssetId='this.id'></baseComponentForm>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <div class="pa-2">
                        <div class="subtitle text-left mb-3">Base Parameters in this Base Asset</div>
                        <baseParameterForm :baseAssetId='this.id' :systemId='input.system'></baseParameterForm>
                    </div>
                </v-tab-item>
                </v-tabs>
        </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import baseComponentForm from '@/components/table/base-component-table.vue';
import baseParameterForm from '@/components/form/base-parameter-form.vue';
	export default {
		  components: {
              baseParameterForm,
              baseComponentForm,
		  },
        props: {
            id: Number   
        },
		data: () => ({
            baseCompHeaders: [
            { text: 'Base Components',  value: 'name',  },
            { text: 'Master Component', value: 'master_component' },
            { text: ''},
            { text: ''},
            ],
			success: false,
			baseAssets: [],
			loading: false,

            input:{
                id: '',
                name: '',
                abbreviation: '',
                system: '',
                icongraphic: null,
            },
            systemList: [],
            unitList: [],
            baseComponent: [],
            baseCompsbyAsset: [],
            baseParameterList: [],
            
            valid: true,
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 50) || 'Name must be less than 50 characters',
            ],
            abbreRules: [
                v => !!v || 'Abbreviation is required',
                v => (v && v.length <= 5) || 'Abbreviation must be less than 5 characters',
            ],
            iconRules: [
                v => !v || v.size < 2000000 || 'Avatar size should be less than 2 MB!',
            ],
            newIcon: null
        }),
		methods: {
            // Main Function
			form() {
                if (this.id) {
                    this.getBaseAsset().then((res) => {
                        this.input.id = res.id;
                        this.input.name = res.name;
                        this.input.system = res.system;
                        this.input.abbreviation = res.abbreviation;
                        this.input.icongraphic = res.icongraphic;
                        this.input.capacity_unit = res.capacity_unit;
                        this.input.consumption_unit = res.consumption_unit;
                    });
                }
            },
			async getBaseAsset() {
                try {
                    this.loading = true
                    const res = await this.$api.baseAsset.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			save() {
                this.saveBaseAsset().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'baseAssetList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                                if (this.id) {
                                    this.form();
                                } else {
                                }
                            }
                        })
                    }
                })
			},
			async saveBaseAsset() {
                try {
                    let res;
                    this.saving = true
                    let formData = new FormData();

                    formData.append("id", this.input.id);
                    formData.append("name", this.input.name);
                    formData.append("system", this.input.system);
                    formData.append("capacity_unit", this.input.capacity_unit);
                    formData.append("consumption_unit", this.input.consumption_unit);
                    formData.append("abbreviation", this.input.abbreviation);
                    if (this.newIcon) {
                        formData.append("icongraphic", this.newIcon);
                    }
                    if (this.input.id) {
                        res = await this.$api.baseAsset.saveForm( this.input.id, formData );
                    } else {
                        res = await this.$api.baseAsset.new( formData );
                    }
                    return res;
                } catch (error) {
                    // handle error
                    console.log(error);
                } finally {
                    this.saving = false
                }
            },
			quitEdit() {
                this.$router.push({ name: 'baseAssetList' })
            },
            // Additional Infos
            getSystemList() {
			    this.getSystems().then(res => this.systemList = res);
            },
			async getSystems() {
                try {
                    const res = await this.$api.system.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getUnitList() {
			    this.getUnits().then(res => this.unitList = res);
            },
			async getUnits() {
                try {
                    const res = await this.$api.unit.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
		},
		mounted () {
            this.getBaseAsset();
            this.getSystemList();
            this.getUnitList();
            this.form();
		},
	}
</script>