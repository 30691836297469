<template>
    <v-container fluid class="list-team">
      <v-row>
        <v-col cols="12">
            <div class="py-2 px-4 text-left command-button-list">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon>mdi-arrow-left</v-icon>
                    Back
                </v-btn>
            </div>
            <v-card class="pa-3 mb-3 mx-auto text-right">
                <teamForm v-if="!loading" :teamId="this.teamId" v-on:return-form="quitEdit"></teamForm>
                <v-divider light></v-divider>
                <userTable v-if="!loading && this.teamId" :teamId="this.teamId"></userTable>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import teamForm from '@/components/form/team-form.vue';
import userTable from '@/components/table/user-table.vue';
	export default {
        components: {
            teamForm,
            userTable
        },
        props: {
            id: Number   
        },
		data: () => ({
            loading: true,
            teamId: null,
		}),
		methods: {
			quitEdit() {
                this.$router.push({ name: 'teamList' })
            },
		},
		mounted () {
            this.teamId = this.id;
            this.loading = false;
		},
	}
</script>