<template>
    <div>
        <v-data-table 
            :loading="this.loading" loading-text="Loading... Please wait"
            :headers="headersParameterPlan"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items="indexedParameters"
            :items-per-page="20"
            :footer-props="{
                itemsPerPageOptions: [20,40,60],
            }" 
            class="full-width">
            <template v-slot:item="row">
                <tr>
                <td>
                    <v-row>
                        <v-col cols="10">
                            <v-select
                            :items="parameterList" 
                            item-value="id"
                            name="item"
                            item-text="name"
                            label="Parameter" 
                            v-model="row.item.parameter"
                            >
                            <template slot='selection' slot-scope='{ item }'>
                                <span v-if="item.base_parameter">{{ getParamName(item.base_parameter) }}</span> 
                            </template>
                            <template slot='item' slot-scope='{ item }'>
                                <span v-if="item.base_parameter">{{ getParamName(item.base_parameter) }}</span>
                            </template>
                            </v-select>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field
                                label="Sort Order" 
                                v-model="row.item.sort_order" 
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </td>
                <td>
                    <v-row no-gutters>
                        <v-btn-toggle>
                            <v-btn small fab @click="save(row.item.id, row.item.parameter, row.item.sort_order, row.item.asset_plan)">
                                <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                            <v-btn small fab @click="remove(row.item.index, row.item.id)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </v-row>
                </td>
                </tr>
            </template>
        </v-data-table>
        <div><v-btn class="mr-4" @click="newParameterPlan()">Add Parameter Plan</v-btn></div>
    </div>
    
</template>
<script>
	export default {
        props: {
            assetPlanId: {
                type: Number
            },
            assetId: {
                type: Number
            }
        },
		data() {
            return {
                headersParameterPlan: [
                { text: 'Parameters', align: 'left', sortable: true, value: 'parameter', },
                { text: ''}
                ],
                id: [],
                loading: true,
                parameterPlansbyAssetPlan: [],
                users:[],
                parameterList: [],
                assetPlan: 0,
                masterParamList: [],
                baseParamList:[],
            }
        },
        computed: {
            indexedParameters () {
                return this.parameterPlansbyAssetPlan.map((item, index) => ({
                    index: index,
                    ...item
                }))
            },
        },
        methods: {
            list() {
			    this.getParameterPlanbyAssetPlan().then((res) => {this.parameterPlansbyAssetPlan = res});
            },
			async getParameterPlanbyAssetPlan() {
                try {
                    this.loading = true
                    const res = await this.$api.paramPlan.search( { asset_plan:this.assetPlanId });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
            getAdditionalData() {
			    this.getParamsbyAsset().then(res => this.parameterList = res);
			    this.getMastParam().then(res => this.masterParamList = res);
			    this.getBaseParameters().then(res => this.baseParamList = res);
			    this.getAssets().then(res => this.assetListFull = res);
            },
			async getParamsbyAsset() {
                try {
                    const res = await this.$api.parameter.search({ asset: this.assetId });
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getMastParam() {
                try {
                    const res = await this.$api.mastParam.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getBaseParameters() {
                try {
                    const res = await this.$api.baseParam.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
			async getAssets() {
                try {
                    const res = await this.$api.asset.list();
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                }
            },
            getAssetName(asset_id) {
                if ( this.assetListFull.length !== 0 && asset_id ) {
                    return this.assetListFull.find(asset => asset.id === asset_id).name;
                }
            }, 
            getParamName(param_id) {
                if ( this.baseParamList.length !== 0 && param_id ) {
                    return (this.masterParamList.find(baseParam => baseParam.id === (this.baseParamList.find(baseParam => baseParam.id === param_id).master_parameter)).name)
                }
            },
            newParameterPlan() {
                this.parameterPlansbyAssetPlan.push({id: '', parameter: '', sort_order: '', asset_plan: this.assetPlan});
            },
            save(id, parameter, sort_order) {
                this.saveParamPlan(id, parameter, sort_order).then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            confirmButtonText: 'Ok',
                        }).then((result) => {
                            this.list();
                        })
                    }
                });
			},
			async saveParamPlan(id, parameter, sort_order, asset_plan) {
                try {
                    let res;
                    this.saving = true
                    if (id) {
                        res = await this.$api.paramPlan.save( {id: id, parameter: parameter, sort_order: sort_order, asset_plan: this.assetPlanId });
                    } else {
                        res = await this.$api.paramPlan.new({ parameter: parameter, sort_order: sort_order, asset_plan: this.assetPlanId });
                    }
                    return res;
                } catch (error) {
                    // handle error
                } finally {
                    this.saving = false
                }
            },
            remove(index, id) {
                if (id) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Are you sure to delete this?',
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        if (result.value) {
                            this.$api.paramPlan.delete(id).then((res) => {
                                this.list();
                            });
                        }
                    })
                } else {
                    this.$delete(this.parameterPlansbyAssetPlan, index)
                }
            },
        },
        mounted(){
            this.list();
            this.getAdditionalData();
        }
    }
</script>