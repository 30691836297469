<template>
    <v-container fluid class="list-attribute">
      <v-row>
        <v-col cols="12">
            <div class="py-2 px-4 text-left command-button-list">
                <v-btn @click="quitEdit()" dark color="secondary">
                    <v-icon>mdi-arrow-left</v-icon>
                    Back
                </v-btn>
            </div>
            <v-card v-if="!loading" class="pa-3 mb-3 mx-auto text-left">
            <v-form lazy-validation
                        ref="form"
                        v-model="valid">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                label="Name" 
                                v-model="input.name"
                                :rules="nameRules"
                                :counter="50"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" md="3">
                            <label>Icongraphic</label><br/>
                            <v-img v-if="input.icongraphic" :src="input.icongraphic" width="180" height="180"></v-img>
                            <v-img v-if="!input.icongraphic" :src="icongraphicPlaceholder" width="180" height="180"></v-img>
                        </v-col>
                        <v-col cols="6" md="3">
                            <v-file-input
                                ref="file"
                                :rules="iconRules"
                                accept="image/png, image/jpeg"
                                placeholder="Upload"
                                prepend-icon="mdi-camera"
                                label="Add or Replace Icongraphic"
                                v-model="newIcon"
                            ></v-file-input>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="text-right">
                            <v-btn class="mr-4" color="primary" :disabled="!valid" @click="save()"><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
	export default {
		  components: {
		  },
          props: ['id'],
		data: () => ({
            loading: false,
            success: false,

            input:{
                id: '',
                name: '',
                icongraphic: null,
            },
            valid: true,
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 50) || 'Name must be less than 50 characters',
            ],
            iconRules: [
                v => !v || v.size < 2000000 || 'Avatar size should be less than 2 MB!',
            ],
            newIcon: null
		}),
		methods: {
            // Main Function
			form() {
                if (this.id) {
                    this.getMastParam().then((res) => {
                        this.input.id = res.id;
                        this.input.name = res.name;
                        this.input.icongraphic = res.icongraphic;
                    });
                }
            },
			async getMastParam() {
                try {
                    this.loading = true
                    const res = await this.$api.mastParam.get(this.id);
                    return res.data;
                } catch (error) {
                    // handle error
                } finally {
                    this.loading = false
                }
            },
			save() {
                this.saveMastParam().then((res) => {
                    if (res.status == 200 || res.status == 201 ) {
                        this.$swal({
                            icon: 'success',
                            title: 'Saved',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: 'Return',
                            cancelButtonText: 'Cancel',
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'masterParameterList' })
                            } else {
                                this.input.id = res.data.id;
                                this.form();
                            }
                        })
                    }
                })
			},
			async saveMastParam() {
                try {
                    let res;
                    this.saving = true
                    let formData = new FormData();

                    formData.append("id", this.input.id);
                    formData.append("name", this.input.name);
                    if (this.newIcon) {
                        formData.append("icongraphic", this.newIcon);
                    }
                    if (this.input.id) {
                        res = await this.$api.mastParam.saveForm( this.input.id, formData );
                    } else {
                        res = await this.$api.mastParam.new( formData );
                    }
                    return res;
                } catch (error) {
                    // handle error
                    console.log(error);
                } finally {
                    this.saving = false
                }
            },
			quitEdit() {
                this.$router.push({ name: 'masterParameterList' })
            },
		},
		mounted () {
			this.form();
		},
	}
</script>