<template>
    <div>
        <v-list dense class="text-left">
            <v-list-item link to="/checklist-checks">
                <v-list-item-action>
                    <v-icon>mdi-format-list-checkbox</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Checklist Check</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item link to="/checklist-verify">
                <v-list-item-action>
                    <v-icon>mdi-format-list-checks</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Checklist Verify</v-list-item-title>
                </v-list-item-content>
            </v-list-item> -->
        </v-list>
        
        <v-divider></v-divider>
    </div>
</template>
<script>
	export default {
		  components: {
		  },
		data: () => ({
            indexName: ['', 'one', 'two', 'three', 'four', 'five'],
        }),
        props: {
            globalPref: Array
        },
        computed: {
			maxLevel: function() {
				if (this.globalPref.length > 0) {
					return (this.globalPref.find(pref => pref.name === 'maximum_level').value);
				} else {
                    return 5;
                }
			}
        },
        methods: {
            getLevelName(levelLabel) {
                if ( this.globalPref.length > 0 && levelLabel !== undefined) {
                    return this.globalPref.find(pref => pref.name === levelLabel).value;
                }
            },
        },
		mounted: function () {
        },
    }
</script>