<template>
    <div class="pa-5">
        <v-form @submit.prevent="filter()">
            <v-text-field
                label="Name" 
                v-model="filterFields.name"
            ></v-text-field>
            <v-select
            :items="frequencyOption" 
            item-value="value"
            name="item"
            item-text="name"
            label="Frequency" 
            v-model="filterFields.frequency"
            ></v-select>
            <v-select
            :items="statusOption" 
            item-value="value"
            name="item"
            item-text="name"
            label="Status" 
            v-model="filterFields.is_active"
            ></v-select>
            <v-btn color="primary" type="submit">Filter</v-btn>
        </v-form>
    </div>
</template>
<script>
	export default {
		  components: {
          },
		data: () => ({
            systemListSelect:[],
            statusOption: [
                {
                    value: '',
                    name: "All"
                },
                {
                    value: true,
                    name: "Active"
                },
                {
                    value: false,
                    name: "Past / Inactive"
                },
            ],
            frequencyOption: [
                {
                value: "DAILY",
                name: "daily"
                },
                {
                value: "WEEKLY_INTERVAL",
                name: "weekly (interval)"
                },
                {
                value: "WEEKLY_NUMBER",
                name: "weekly (number)"
                },
                {
                value: "MONTHLY_DAY",
                name: "monthly (day)"
                },
                {
                value: "MONTHLY_DATE",
                name: "monthly (date)"
                },
            ]
        }),
        props: {
            tableType: {
                type: String,
            },
            filterFields: {
                type: Object,
            },
            systemList: {
                type: Array,
                required: false
            }
        },
        computed: {
        },
        methods: {
            filter(event){
                this.$emit('filteredData', this.filterFields)
            }
        },
        mounted() {
            this.systemListSelect = this.systemList
            this.systemListSelect.unshift({
                id:'',
                name: 'All Systems',
            });
        }
    }
</script>